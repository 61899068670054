import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, Empty, Table } from 'antd'
import useLandingPageHooks from 'src/Hooks/MentorHooks/useLandingPageHooks'
import FinalStudentReport from 'src/Pages/CounselorPages/Student Enrollment/Students/StudentChildComponent/ViewDetail'

const MentorGroupDetail = () => {
  const { groupDetail, loading, projectAssigned } = useLandingPageHooks()
  const { groupId } = useParams()
  const navigate = useNavigate()

  const [isDetailView, setIsDetailView] = useState(false)
  const [selectedReport, setSelectedReport] = useState(null)

  const viewDetail = (record) => {
    setSelectedReport({
      studentId: record.studentId,
      careerPathReportId: record.careerPathReportId,
    })
    setIsDetailView(true)
  }

  const Back = () => {
    setIsDetailView(false)
  }

  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Academic Year',
      dataIndex: 'academicYear',
      key: 'academicYear',
    },
    {
      title: 'View Personality Report',
      key: 'hasPersonalityReport',
      render: (_, record) =>
        record.hasPersonalityReport ? (
          <Button type="default" onClick={() => viewDetail(record)}>
            View Detail
          </Button>
        ) : (
          'N/A'
        ),
    },
  ]

  const handleManageClick = (id) => {
    navigate(`/projects-Detail/${id}`) // Navigate with project ID
  }

  return (
    <>
      {isDetailView ? (
        <FinalStudentReport
          studentId={selectedReport.studentId}
          careerPathReportId={selectedReport.careerPathReportId}
          Back={Back}
        />
      ) : (
        <Card
          title="Group Detail"
          className="card-professional"
          loading={loading}
          extra={
            <Button type="default" onClick={() => navigate('/mentor-Dashboard')}>
              Back
            </Button>
          }
        >
          <>
            {projectAssigned ? (
              <Button type="primary" onClick={() => handleManageClick(groupDetail.projectId)}>
                View Project
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => navigate(`/assign-internship-project/${groupId}`)}
              >
                Assign Project
              </Button>
            )}
            <br />
            <br />
            {groupDetail ? (
              <>
                <p>
                  <strong>Group Name:</strong> {groupDetail.groupName}
                </p>
                <p>
                  <strong>Created At:</strong> {new Date(groupDetail.createdAt).toLocaleString()}
                </p>
                <h3>Group Members</h3>
                <Table dataSource={groupDetail.members} columns={columns} rowKey="memberId" />
              </>
            ) : (
              <Empty description="No group details found" />
            )}
          </>
        </Card>
      )}
    </>
  )
}

export default MentorGroupDetail

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import apiClient from 'src/Common/apiClient'

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleCancelClick = () => {
    navigate('/studentDashboard')
  }

  const handleChangePassword = async () => {
    setError('')
    setMessage('')

    // Validate required fields
    if (!oldPassword) {
      setError('Old password is required.')
      return
    }
    if (!newPassword) {
      setError('New password is required.')
      return
    }
    if (!confirmPassword) {
      setError('Confirm password is required.')
      return
    }

    // Validate new password length
    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long.')
      return
    }

    // Validate new password and confirm password match
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.')
      return
    }

    try {
      setLoading(true)
      const response = await apiClient.post('/Account/Change-password', {
        userId: localStorage.getItem('userId') || '',
        oldPassword: oldPassword,
        newPassword: newPassword,
      })

      if (response.status === 200) {
        navigate('/login', {
          state: { successMessage: 'Password changed successfully' },
        })
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
      } else {
        setError('Failed to change password. Please try again.')
      }
    } catch (error) {
      console.error('Error changing password:', error)
      setError(error.response.data)
    } finally {
      setLoading(false)
    }
  }

  const inputStyle = {
    width: '100%',
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    fontSize: '16px',
  }

  const passwordToggleStyle = {
    position: 'absolute',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    fontSize: '18px',
  }

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    width: '40%',
  }

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    width: '100%',
  }

  const buttonStyle = {
    width: '48%',
    padding: '12px 20px',
    borderRadius: '8px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: 'white',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s',
  }

  const cancelButtonStyle = {
    backgroundColor: '#f44336',
  }

  const buttonDisabledStyle = {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  }

  const headingStyle = {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#4CAF50',
  }

  return (
    <div style={{ marginTop: '60px', justifyContent: 'center', display: 'flex' }}>
      <div style={containerStyle}>
        <h5 style={{ textAlign: 'center' }}>Change Password</h5>
        {message && <p style={{ color: 'green', fontWeight: 'bold' }}>{message}</p>}
        {error && <p style={{ color: 'red', fontSize: 'medium' }}>{error}</p>}

        {/* Old Password */}
        <div style={{ marginBottom: '20px', width: '100%' }}>
          <h6 style={headingStyle}>Old Password</h6>
          <div style={{ position: 'relative' }}>
            <input
              type={oldPasswordVisible ? 'text' : 'password'}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder="Enter your old password"
              style={inputStyle}
            />
            <span
              onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
              style={passwordToggleStyle}
            >
              {oldPasswordVisible ? '👁️' : '👁️‍🗨️'}
            </span>
          </div>
        </div>

        {/* New Password */}
        <div style={{ marginBottom: '20px', width: '100%' }}>
          <h6 style={headingStyle}>New Password</h6>
          <div style={{ position: 'relative' }}>
            <input
              type={newPasswordVisible ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              style={inputStyle}
            />
            <span
              onClick={() => setNewPasswordVisible(!newPasswordVisible)}
              style={passwordToggleStyle}
            >
              {newPasswordVisible ? '👁️' : '👁️‍🗨️'}
            </span>
          </div>
        </div>

        {/* Confirm Password */}
        <div style={{ marginBottom: '20px', width: '100%' }}>
          <h6 style={headingStyle}>Confirm Password</h6>
          <div style={{ position: 'relative' }}>
            <input
              type={confirmPasswordVisible ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
              style={inputStyle}
            />
            <span
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              style={passwordToggleStyle}
            >
              {confirmPasswordVisible ? '👁️' : '👁️‍🗨️'}
            </span>
          </div>
        </div>

        {/* Buttons */}
        <div style={buttonContainerStyle}>
          <button
            type="button"
            onClick={handleCancelClick}
            style={{ ...buttonStyle, ...cancelButtonStyle }}
          >
            Cancel
          </button>
          <button
            onClick={handleChangePassword}
            style={{
              ...buttonStyle,
              ...(loading ? buttonDisabledStyle : {}),
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="white" /> : 'Change Password'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword

import React, { useState, useEffect } from 'react'
import '../navbar.scss'
// react-router components
import { useLocation, useNavigate } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @material-ui core components
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import logoSpotify from 'src/assets/images/p.png'
import changePassword from 'src/assets/images/chp.png'

import adminPic from 'src/assets/images/p.png'
import SoftBox from 'src/Components/softData/SoftBox'

import Breadcrumbs from 'src/Components/navbarData/Breadcrumbs'
import AccountItems from 'src/Components/navbarData/AccountItems/AccountData'

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from 'src/Components/navbarData/Navbars/styles'

import { useSoftUIController, setTransparentNavbar, setMiniSidenav } from 'src/context'

// Images
import logout from 'src/assets/images/logOUT.png'
import { Button, Dropdown, Menu } from 'antd'
import axios from 'axios'

function DashboardNavbar({ absolute, light, isMini }) {
  const userId = localStorage.getItem('userId')
  const userRole = localStorage.getItem('role')
  const token = localStorage.getItem('token')
  const [navbarType, setNavbarType] = useState()
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller
  const route = useLocation().pathname.split('/').slice(1)
  const [profileImage, setProfileImage] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType('sticky')
    } else {
      setNavbarType('static')
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
    }
    window.addEventListener('scroll', handleTransparentNavbar)
    handleTransparentNavbar()
    return () => window.removeEventListener('scroll', handleTransparentNavbar)
  }, [dispatch, fixedNavbar])

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/Mentor/profile?mentorId=${userId}`,
          { headers: { Authorization: `Bearer ${token}` } },
        )

        const profileCompleted = response.data.isProfileCompleted
        setProfileImage({
          id: response.data.counselorId,
          image: `https://soft.recipe4foodies.com/${response.data.profileImageUrl}` || '',
        })
        const name = (
          <strong>
            {response.data.firstName} {response.data.lastName}
          </strong>
        )
        // if (!profileCompleted) {
        //   setShowAlert(true)
        //   if (location.pathname === '/profileCounselor') {
        //     setAlertText(<span>{name}, Please Update your profile.</span>)
        //   } else {
        //     setAlertText(
        //       <span>
        //         You have not completed your profile yet! &nbsp;&nbsp;&nbsp;
        //         <Button type="primary" onClick={handleProfile} size="small">
        //           Complete Profile
        //         </Button>
        //       </span>,
        //     )
        //   }
        // } else {
        //   setShowAlert(false)
        //   setAlertText('Your profile is not completed!')
        // }
      } catch (error) {
        console.error('Error fetching profile data:', error)
      }
    }
    fetchProfileData()
  }, [userId, token])

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav)

  const handleCloseAccount = () => {
    localStorage.setItem('logout', true)
    localStorage.clear()
    navigate('/login')
  }

  const handleProfile = () => {
    navigate('/mentor-profile')
  }
  const handleChangePassword = () => {
    navigate('/change-password')
  }

  const backToMultiLogin = () => {
    navigate('/multiLogin')
  }

  const account = () => (
    <Menu>
      <AccountItems
        image={<img src={logoSpotify} alt="person" />}
        title={['Profile']}
        onClick={handleProfile}
      />
      <AccountItems
        image={<img src={changePassword} alt="person" />}
        title={['Change Password']}
        onClick={handleChangePassword}
      />
      <AccountItems
        color="secondary"
        image={<img src={logout} alt="person" />}
        title={['Logout']}
        onClick={handleCloseAccount}
      />
    </Menu>
  )

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            {userRole === 'Admin' && ( // Conditionally render the button
              <Button
                type="primary"
                onClick={backToMultiLogin}
                ghost
                style={{ marginRight: '10px' }}
              >
                Back to MultiLogin
              </Button>
            )}

            <IconButton
              size="small"
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon className={light ? 'text-white' : 'text-dark'}>
                {miniSidenav ? 'menu_open' : 'menu'}
              </Icon>
            </IconButton>
            <Dropdown overlay={account} trigger={['click']} placement="bottomRight">
              <IconButton sx={navbarIconButton} size="large">
                <img
                  src={userRole === 'Admin' ? adminPic : profileImage?.image || adminPic}
                  alt="Profile"
                  style={{ width: '44px', height: '44px', borderRadius: '50%' }}
                  onError={(e) => {
                    e.target.src = adminPic
                  }}
                />
              </IconButton>
            </Dropdown>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  )
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
}

export default DashboardNavbar

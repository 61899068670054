import { createTheme } from '@mui/material/styles'

import colors from 'src/assets/theme/base/colors'
import breakpoints from 'src/assets/theme/base/breakpoints'
import typography from 'src/assets/theme/base/typography'
import boxShadows from 'src/assets/theme/base/boxShadows'
import borders from 'src/assets/theme/base/borders'
import globals from 'src/assets/theme/base/globals'

import boxShadow from 'src/assets/theme/functions/boxShadow'
import hexToRgb from 'src/assets/theme/functions/hexToRgb'
import linearGradient from 'src/assets/theme/functions/linearGradient'
import pxToRem from 'src/assets/theme/functions/pxToRem'
import rgba from 'src/assets/theme/functions/rgba'

import sidenav from 'src/assets/theme/components/sidenav'
import list from 'src/assets/theme/components/list'
import listItem from 'src/assets/theme/components/list/listItem'
import listItemText from 'src/assets/theme/components/list/listItemText'
import breadcrumbs from 'src/assets/theme/components/breadcrumbs'
import appBar from 'src/assets/theme/components/appBar'
import container from 'src/assets/theme/components/container'
import svgIcon from 'src/assets/theme/components/svgIcon'
import menu from 'src/assets/theme/components/menu'
import menuItem from 'src/assets/theme/components/menu/menuItem'

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
      },
    },
    MuiDrawer: { ...sidenav },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiBreadcrumbs: { ...breadcrumbs },
    MuiAppBar: { ...appBar },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiSvgIcon: { ...svgIcon },
  },
})

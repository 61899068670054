import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@mui/material'
import { useSignalR } from 'src/context/SingleRContext'

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '8px',
  fontSize: '14px',
  color: 'white',
}

const CounselorChatBadge = ({ dataKey, name }) => {
  const { unreadMessages } = useSignalR()
  const badgeCount = dataKey === 'message' ? unreadMessages : 0

  return (
    <div style={containerStyle}>
      <span>{name}</span>
      {badgeCount > 0 && (
        <Badge
          badgeContent={badgeCount}
          color="error"
          sx={{
            fontSize: '10px',
            marginLeft: '-7px',
            width: '10px',
            minWidth: '16px',
            borderRadius: '50%',
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      )}
    </div>
  )
}

CounselorChatBadge.propTypes = {
  dataKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  unreadMessages: PropTypes.number.isRequired,
}

export default CounselorChatBadge

import { useState, useEffect } from 'react'
import { message } from 'antd'
import {
  assignTask,
  fetchProjectData,
  toggleResubmission,
} from 'src/Services/MentorServices/ProjectsService'

export const useProject = (id) => {
  const [projectData, setProjectData] = useState(null)
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')
  const [updating, setUpdating] = useState(false)

  const fetchProjects = async () => {
    try {
      const data = await fetchProjectData(id, token)
      setProjectData(data)
      if (typeof window.updateBadgeCount === 'function') {
        window.updateBadgeCount()
      }
    } catch (error) {
      message.error('Error fetching project data')
    } finally {
      setLoading(false)
    }
  }

  const handleAddTask = async (values) => {
    try {
      await assignTask({ ...values, internshipProjectId: id }, token)
      message.success('Task assigned successfully')
      fetchProjects() // Refresh project data
    } catch (error) {
      message.error('Error assigning task')
    }
  }

  const handleToggleResubmission = async (taskId, enableSubmission) => {
    setUpdating(true)
    try {
      await toggleResubmission(taskId, token)
      message.success(`Resubmission ${enableSubmission ? 'disabled' : 'enabled'} successfully!`)

      // Update local state
      setProjectData((prevData) => ({
        ...prevData,
        tasks: prevData.tasks.map((task) =>
          task.taskId === taskId ? { ...task, enableSubmission: !enableSubmission } : task,
        ),
      }))
    } catch (error) {
      message.error('Failed to toggle resubmission. Please try again.')
    } finally {
      setUpdating(false)
    }
  }

  useEffect(() => {
    fetchProjects()
  }, [id])

  return { projectData, loading, handleAddTask, updating, handleToggleResubmission }
}

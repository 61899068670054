import React, { useEffect, useState } from 'react'
import { Tabs, Collapse, Card, Row, Col, Typography } from 'antd'
import { ToolOutlined, BulbOutlined, FileTextOutlined } from '@ant-design/icons'
import axios from 'axios'

const { TabPane } = Tabs
const { Panel } = Collapse
const { Title, Text } = Typography

const ProjectLibrary = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const [projects, setProjects] = useState([])
  const [framework, setFramework] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/InternshipProject/Library/Mentor?mentorId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: '*/*',
            },
          },
        )
        setProjects(response.data.projects || [])
        setFramework(response.data.projectFramework || [])
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <Card loading={loading} title="Project Library" className="card-professional">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Library's Projects" key="1">
          <h3>Library`s Projects List</h3>
          <Row gutter={[16, 16]}>
            {projects.map((project, index) => (
              <Col key={index} xs={24} sm={12} md={12}>
                <Card
                  bordered
                  style={{
                    height: 300,
                    borderRadius: 12,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Title level={4} style={{ marginBottom: 10 }}>
                    {project.title}
                  </Title>

                  <div style={{ maxHeight: 200, overflowY: 'auto', paddingRight: 10 }}>
                    <Row gutter={[12, 8]}>
                      <Col span={24}>
                        <strong>
                          <FileTextOutlined /> Description:
                        </strong>
                        <Text> {project.description}</Text>
                      </Col>

                      <Col span={24}>
                        <strong>
                          <BulbOutlined /> Skills Learned:
                        </strong>
                        <Text> {project.skillsLearned.join(', ')}</Text>
                      </Col>

                      <Col span={24}>
                        <strong>
                          <ToolOutlined /> Tools:
                        </strong>
                        <Text> {project.tools.join(', ')}</Text>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </TabPane>

        <TabPane tab="Project Framework" key="2">
          <h3>Project Execution Framework</h3>
          <Collapse accordion>
            {framework.map((step, index) => (
              <Panel header={step.title} key={index}>
                <ul>
                  {step.tasks.map((task, i) => (
                    <li key={i}>{task}</li>
                  ))}
                </ul>
              </Panel>
            ))}
          </Collapse>
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default ProjectLibrary

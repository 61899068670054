import React from 'react'
import { Table, Button, Card, Row, Col, Typography, Space, Empty, Tooltip, Switch } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import useMentorDetail from 'src/Hooks/MentorHooks/useMentorDetail'
import { CommentOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const MentorDetail = () => {
  const { id } = useParams()
  const {
    data,
    loading,
    showApplication,
    setShowApplication,
    handleToggle,
    sharing,
    handleShareProjectPlan,
  } = useMentorDetail(id)
  const navigate = useNavigate()

  const handleGroupConversationClick = () => {
    if (data.program.conversationId) {
      navigate('/messaging', { state: { selectedChatId: data.program.conversationId } })
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Uploaded On',
      dataIndex: 'uploadedOn',
      key: 'uploadedOn',
      render: (text, record) => (record.submittedFileUrl ? new Date(text).toLocaleString() : 'N/A'),
    },
    {
      title: 'Submitted File Name',
      dataIndex: 'submittedFileName',
      key: 'submittedFileName',
      render: (text, record) =>
        record.submittedFileUrl ? (
          <a
            href={`https://soft.recipe4foodies.com${encodeURI(record.submittedFileUrl)}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {text}
          </a>
        ) : (
          'No File Submitted Yet'
        ),
    },
    {
      title: 'Enable Resubmission',
      key: 'action',
      render: (_, record) => {
        const isDisabled = !record.submittedFileUrl

        return (
          <Tooltip title={isDisabled ? 'No file is submitted yet!' : ''}>
            <span>
              <Switch
                checked={record.enableSubmission}
                disabled={isDisabled}
                onChange={() => handleToggle(record.id)}
              />
            </span>
          </Tooltip>
        )
      },
    },
  ]

  return (
    <Card
      title="Mentor Detail"
      className="card-professional"
      extra={
        <Button type="default" onClick={() => navigate(-1)}>
          Back
        </Button>
      }
      loading={loading}
    >
      <div style={{ padding: '32px', backgroundColor: '#f5f5f5' }}>
        {data ? (
          <>
            {showApplication ? (
              <Card bordered={false}>
                <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
                  <Col flex="auto" style={{ textAlign: 'center' }}>
                    <Title level={3} style={{ margin: 0 }}>
                      Idea Factory Detail
                    </Title>
                  </Col>
                  <Col>
                    <Button onClick={() => setShowApplication(false)} type="default">
                      Back
                    </Button>
                  </Col>
                </Row>

                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Text>
                    <strong>Idea Type:</strong> &nbsp; {data.application?.ideaType}
                  </Text>
                  <Text>
                    <strong>Describe the Idea:</strong> &nbsp;{data.application?.description}
                  </Text>
                  <Text>
                    <strong>Explain the Benefit (Max 200 words):</strong> &nbsp;
                    {data.application?.benefitOfIdea}
                  </Text>
                  <Text>
                    <strong>
                      What resources are needed to complete this project successfully?
                    </strong>{' '}
                    &nbsp;
                    {data.application?.resourcesForProject}
                  </Text>
                  <Text>
                    <strong>What are the potential challenges and risks?</strong> &nbsp;
                    {data.application?.potentialRisks}
                  </Text>
                </Space>
              </Card>
            ) : (
              <>
                <Button type="primary" onClick={() => setShowApplication(!showApplication)}>
                  Student Idea Factory Submitted Detail
                </Button>
                <br />
                <br />
                <Card bordered={false}>
                  <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
                    <Col flex="auto">
                      <Title level={4} style={{ margin: 0 }}>
                        Student Information
                      </Title>
                    </Col>
                  </Row>

                  <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Text>
                      <strong>Name:</strong> {data?.student?.studentName}
                    </Text>
                    <Text>
                      <strong>Year:</strong> {data?.student?.year}
                    </Text>

                    <Space>
                      <Button
                        type="primary"
                        icon={<CommentOutlined />}
                        onClick={handleGroupConversationClick}
                      >
                        Chat with Student
                      </Button>
                      {data?.program?.isProjectPlanSent ? (
                        <Text type="success" style={{ fontSize: '16px' }}>
                          Project plan shared successfully.
                        </Text>
                      ) : (
                        <Button
                          type="primary"
                          loading={sharing}
                          disabled={sharing}
                          onClick={handleShareProjectPlan}
                        >
                          {sharing ? 'Please wait...' : 'Share Project Plan'}
                        </Button>
                      )}
                    </Space>
                  </Space>
                </Card>
                <br />
                <Table
                  columns={columns}
                  dataSource={data.program?.uploadedTempletes || []}
                  rowKey="id"
                  bordered
                />
              </>
            )}
          </>
        ) : (
          <Empty description="No data available" />
        )}
      </div>
    </Card>
  )
}

export default MentorDetail

import { useState } from 'react'
import { login } from 'src/Pages/StudentPages/ApiServices/LoginService'

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = async (email, password) => {
    setIsLoading(true)

    try {
      const data = await login(email, password)

      localStorage.setItem('userId', data.userId)
      localStorage.setItem('token', data.token)
      localStorage.setItem('role', data.roles)
      localStorage.setItem('presenceHubStarted', 'true')

      if (data.roles.includes('Student')) {
        window.location.href = '/studentDashboard'
      } else if (data.roles.includes('Parent')) {
        window.location.href = '/parentDashboard'
      } else if (data.roles.includes('Counselor')) {
        window.location.href = '/counselorDashboard'
      } else if (data.roles.includes('Tutor')) {
        window.location.href = '/tutorDashboard'
      } else if (data.roles.includes('Admin')) {
        window.location.href = '/multiLogin'
      } else if (data.roles.includes('Librarian')) {
        window.location.href = '/library-dashboard'
      } else if (data.roles.includes('BAMAdmin')) {
        window.location.href = '/bamadminDashboard'
      } else if (data.roles.includes('Mentor')) {
        window.location.href = '/mentor-Dashboard'
      } else {
        window.location.href = '/login' // Default fallback
      }
    } catch (error) {
      console.error('Login failed:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return { handleLogin, isLoading }
}

import React, { useEffect, useState } from 'react'
import { Card, Button, Typography, Empty } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'

const { Title, Text } = Typography

const ProgressReportDetail = () => {
  const { reportId } = useParams()
  const navigate = useNavigate()
  const [report, setReport] = useState(null)
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')

  const fetchStatusReport = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/InternshipStatusReport/${reportId}`,
        {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setReport(response.data)
    } catch (error) {
      console.error('Error fetching report:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (reportId) {
      fetchStatusReport()
    }
  }, [reportId])

  return (
    <Card
      loading={loading}
      className="card-professional"
      title="Progress Report"
      extra={<Button onClick={() => navigate(-1)}>Back</Button>}
    >
      {report ? (
        <div
          style={{
            maxHeight: '500px',
            overflowY: 'auto',
            paddingRight: '10px',
          }}
        >
          <Card
            style={{
              marginBottom: 16,
              borderRadius: 10,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              padding: 10,
            }}
          >
            <Title level={4} style={{ color: '#1890ff', marginBottom: 10 }}>
              Week {report.weekNumber}
            </Title>

            {/* Student Name & Group Name Inline */}
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <Text strong>Project Name: </Text>&nbsp;&nbsp;
              <Text>{report.projectName}</Text>
            </div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <Text strong>Group Name: </Text>&nbsp;&nbsp;
              <Text>{report.groupName}</Text>
            </div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <Text strong>Student Name: </Text>&nbsp;&nbsp;
              <Text>{report.studentName}</Text>
            </div>

            <Text strong>Description:</Text>
            <p style={{ marginBottom: 10 }}>{report.reportText}</p>

            <Text type="secondary">
              Submitted on: {new Date(report.submissionDate).toLocaleDateString()}
            </Text>
          </Card>
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '50px 0' }}>
          <Empty description="No report found" />
        </div>
      )}
    </Card>
  )
}

export default ProgressReportDetail

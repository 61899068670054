import 'react-app-polyfill/stable'
import 'core-js'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

const Root = () => {
  useEffect(() => {
    // Disable right-click
    const disableContextMenu = (e) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', disableContextMenu)

    // Disable inspect tools
    const disableInspect = (e) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && e.key === 'I') ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault()
        alert('Inspect tools are disabled!')
      }
    }
    document.addEventListener('keydown', disableInspect)

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu)
      document.removeEventListener('keydown', disableInspect)
    }
  }, [])

  return <App />
}

createRoot(document.getElementById('root')).render(<Root />)
reportWebVitals()

// import 'react-app-polyfill/stable'
// import 'core-js'
// import React from 'react'
// import { createRoot } from 'react-dom/client'
// import App from './App'
// import reportWebVitals from './reportWebVitals'

// createRoot(document.getElementById('root')).render(<App />)
// reportWebVitals()

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Form } from 'antd'
import { setupInterceptors } from 'src/Common/apiClient'
import {
  fetchProfileData,
  updateProfileData,
} from 'src/Services/MentorServices/MentorProfileService'

// Custom Hook for Profile Edit functionality
function useMentorProfile() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  setupInterceptors(navigate)
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true)
        const data = await fetchProfileData(userId, token)

        if (data.profileImageUrl) {
          setPreviewImage(`https://soft.recipe4foodies.com/${data.profileImageUrl}`)
        }

        if (data.dateOfBirth) {
          data.dateOfBirth = dayjs(data.dateOfBirth)
        }
        form.setFieldsValue(data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    fetchProfile()
  }, [userId, token, navigate])

  const handleSubmit = async (values) => {
    const formData = new FormData()
    // Append all form values to FormData
    formData.append('mentorId', userId)
    formData.append('firstName', values.firstName)
    formData.append('middleName', values.middleName)
    formData.append('lastName', values.lastName)
    formData.append('dateOfBirth', new Date(values.dateOfBirth).toISOString().split('T')[0])
    formData.append('gender', values.gender)
    formData.append('state', values.state)
    formData.append('country', values.country)
    formData.append('email', values.email)
    formData.append('expertiseArea', values.expertiseArea)
    if (values.profileImageFile && values.profileImageFile.originFileObj) {
      formData.append('profileImageFile', values.profileImageFile.originFileObj)
    }

    try {
      setLoader(true)
      const response = await updateProfileData(formData, token)
      if (response.status === 200) {
        navigate('/mentor-Dashboard', {
          state: { successMessage: 'Profile Updated Successfully!' },
        })
      }
    } catch (error) {
      console.log('Error', error)
    } finally {
      setLoader(false)
    }
  }

  const initialValues = {
    profileImageFile: null,
    profileImageUrl: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    state: '',
    country: '',
    email: '',
    expertiseArea: '',
  }

  const [isUS, setIsUS] = useState(false)
  const handleCountryChange = (value) => {
    setIsUS(value === 'United States of America')
    if (value !== 'United States of America') {
      form.setFields([
        {
          name: 'zip',
          errors: [],
        },
      ])
    }
    form.validateFields(['zip'])
  }
  return {
    form,
    previewImage,
    loading,
    loader,
    handleSubmit,
    handleCountryChange,
    isUS,
    initialValues,
    setPreviewImage,
  }
}

export default useMentorProfile

import { useState, useEffect } from 'react'
import { message } from 'antd'
import {
  fetchMentorDetail,
  toggleResubmission,
  fileSharing,
} from 'src/Services/MentorServices/MentorDetailService'

const useMentorDetail = (id) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showApplication, setShowApplication] = useState(false)
  const [sharing, setSharing] = useState(false)

  useEffect(() => {
    loadData()
  }, [id])

  const loadData = async () => {
    try {
      const response = await fetchMentorDetail(id)
      setData(response)
    } catch (error) {
      message.error('Failed to load mentor details')
    } finally {
      setLoading(false)
    }
  }

  const handleToggle = async (templateId) => {
    try {
      await toggleResubmission(templateId)
      message.success('Resubmission status updated successfully!')

      // Update state locally
      setData((prevData) => ({
        ...prevData,
        program: {
          ...prevData.program,
          uploadedTempletes: prevData.program.uploadedTempletes.map((item) =>
            item.id === templateId ? { ...item, enableSubmission: !item.enableSubmission } : item,
          ),
        },
      }))
    } catch (error) {
      message.error('Failed to update resubmission status')
    }
  }

  const handleShareProjectPlan = async () => {
    try {
      setSharing(true)
      const response = await fileSharing(id)
      message.success('Project plan shared successfully!')
      loadData()
    } catch (error) {
      console.error(error)
    } finally {
      setSharing(false)
    }
  }

  return {
    data,
    loading,
    showApplication,
    setShowApplication,
    handleToggle,
    sharing,
    handleShareProjectPlan,
  }
}

export default useMentorDetail

import React from 'react'
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import Icon from '@mui/material/Icon'

import SoftBox from 'src/Components/softData/SoftBox'
import SoftTypography from 'src/Components/softData/SoftTypography'

function Breadcrumbs({ icon, title, route, light }) {
  const role = localStorage.getItem('role')
  const roles = localStorage.getItem('roles')
  const roleToDashboard = {
    Parent: '/parentDashboard',
    Student: '/studentDashboard',
    Counselor: '/counselordashboard',
    Tutor: '/tutorDashboard',
    BAMAdmin: '/bamadminDashboard',
  }

  let dashboardRoute = roleToDashboard[role]
  if (role === 'Parent' && roles === '"Student"') {
    dashboardRoute = '/dashboard'
  }

  const routes = Array.isArray(route) ? route.slice(0, -1) : [route]

  return (
    <SoftBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to={dashboardRoute}>
          <SoftTypography
            component="span"
            variant="body2"
            color={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </SoftTypography>
        </Link>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <SoftTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? 'white' : 'dark'}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </SoftTypography>
          </Link>
        ))}
        <SoftTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? 'white' : 'dark'}
          sx={{ lineHeight: 0 }}
        >
          {title.replace('-', ' ')}
        </SoftTypography>
      </MuiBreadcrumbs>
    </SoftBox>
  )
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
}

export default Breadcrumbs

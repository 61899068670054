import React, { useEffect, useState } from 'react'
import { Layout, Card, Table, Row, Col, Empty } from 'antd'
import { Bar, Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import useLandingPage from './useLandingPage'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'antd'

// Register Chart.js Components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const { Content } = Layout

const MentorDashboard = () => {
  const { groupData, loading, columns, chartLoading, statusData, completionData } = useLandingPage()
  const location = useLocation()
  const navigate = useNavigate()
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage)

  // Function to show success message using Modal.success
  const success = (message) => {
    Modal.success({
      content: message,
      onOk: handleOk,
    })
  }

  const handleOk = () => {
    setSuccessMessage(null)
    navigate(location.pathname, { replace: true, state: {} })
    window.location.reload()
  }

  useEffect(() => {
    if (successMessage) {
      success(successMessage)
    }
  }, [successMessage])

  return (
    <Content style={{ padding: '20px' }}>
      <h5 style={{ textAlign: 'center' }}>Welcome, Mentor</h5>

      {/* Assigned Students Table */}
      <Card
        loading={loading}
        className="card-professional"
        title="Assigned Groups"
        style={{ marginTop: 20 }}
      >
        <Table dataSource={groupData} columns={columns} bordered rowKey="groupId" />
      </Card>

      {/* Charts Row */}
      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col xs={24} sm={12} lg={12}>
          <Card loading={chartLoading} className="card-professional" title="Project Status Chart">
            {statusData && statusData.labels.length > 0 ? (
              <Bar
                data={statusData}
                options={{
                  scales: {
                    y: {
                      ticks: {
                        callback: function (value) {
                          return value + '%'
                        },
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let value = context.raw || 0
                          return value + '%'
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              <Empty description="Project status data is not available" />
            )}
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={12}>
          <Card loading={chartLoading} className="card-professional" title="Completion Status">
            <div style={{ width: '355px', height: '300px', margin: '0 auto' }}>
              {completionData && completionData.labels.length > 0 ? (
                <Doughnut data={completionData} options={{ maintainAspectRatio: false }} />
              ) : (
                <Empty description="Completion status data is not available" />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Content>
  )
}

export default MentorDashboard

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Card, List, Typography, Button, Modal, Input, message } from 'antd'
import moment from 'moment'
import { fetchViewHistory } from 'src/Services/MentorServices/IntershipTaskManagementService'

const { Title, Text } = Typography
const { TextArea } = Input

const ViewHistory = () => {
  const { memberId } = useParams()
  const [historyData, setHistoryData] = useState([])
  const [loading, setLoading] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [feedback, setFeedback] = useState('')
  const [submittingFeedback, setSubmittingFeedback] = useState(false)
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (memberId) {
      fetchHistory()
    }
  }, [memberId])

  const fetchHistory = async () => {
    try {
      const data = await fetchViewHistory(memberId)
      setHistoryData(data)
      if (typeof window.updateBadgeCount === 'function') {
        window.updateBadgeCount()
      }
    } catch (error) {
      console.error('Error fetching report status:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenModal = (entry) => {
    setSelectedEntry(entry)
    setFeedback('')
    setModalVisible(true)
  }

  const handleSubmitFeedback = async () => {
    if (!feedback.trim()) {
      message.error('Feedback Required!')
      return
    }

    try {
      setSubmittingFeedback(true)
      await axios.post(
        'https://soft.recipe4foodies.com/api/InternIssueChallenge/feedback',
        {
          id: selectedEntry.id,
          feedback: feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )

      message.success('Feedback successfully submitted!')

      fetchHistory()
      setHistoryData((prevData) =>
        prevData.map((item) =>
          item.id === selectedEntry.id ? { ...item, mentorFeedback: feedback } : item,
        ),
      )

      setModalVisible(false)
    } catch (error) {
      console.error('Error submitting feedback:', error)
      message.error('Feedback submit karne mein error aayi.')
    } finally {
      setSubmittingFeedback(false)
    }
  }

  return (
    <Card
      className="card-professional"
      loading={loading}
      title="📜 View Issue History"
      extra={
        <Button type="default" onClick={() => navigate(-1)}>
          Back
        </Button>
      }
    >
      {historyData.length === 0 ? (
        <Text>No history found for this member.</Text>
      ) : (
        historyData.map((entry) => (
          <Card
            key={entry.id}
            style={{ marginBottom: 20, background: '#fafafa', borderRadius: 10 }}
          >
            <Title level={4} style={{ color: '#1890ff', marginBottom: 10 }}>
              {entry.category}
            </Title>
            <Text type="secondary" style={{ fontSize: '14px' }}>
              Created on: {moment(entry.createdAt).format('MMMM Do YYYY, h:mm A')}
            </Text>

            <List
              bordered
              dataSource={entry.questions}
              style={{ marginTop: 10 }}
              renderItem={(item) => (
                <List.Item style={{ padding: '15px', display: 'block' }}>
                  <Text strong style={{ fontSize: '16px', color: '#333' }}>
                    Q: {item.question}
                  </Text>
                  <br />
                  <Text style={{ fontSize: '14px', color: '#555' }}>A: {item.answer}</Text>
                </List.Item>
              )}
            />

            {entry.mentorFeedback ? (
              <>
                <Text
                  strong
                  style={{
                    fontSize: '15px',
                    color: '#0050b3',
                    marginTop: '10px',
                    display: 'block',
                  }}
                >
                  Mentor Feedback:
                </Text>
                <Text style={{ fontSize: '14px', color: '#333', display: 'block' }}>
                  {entry.mentorFeedback ? entry.mentorFeedback.feedback : 'N/A'}
                </Text>
              </>
            ) : (
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
                onClick={() => handleOpenModal(entry)}
              >
                Provide Feedback
              </Button>
            )}
          </Card>
        ))
      )}

      {/* Modal for Feedback */}
      <Modal
        title="Provide Feedback"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={submittingFeedback}
            type="primary"
            disabled={submittingFeedback}
            onClick={handleSubmitFeedback}
          >
            {submittingFeedback ? 'Submitting' : 'Submit'}
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          placeholder="Enter your feedback here..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          required
        />
      </Modal>
    </Card>
  )
}

export default ViewHistory

import React, { Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { SoftUIControllerProvider } from 'src/context'
import 'bootstrap/dist/css/bootstrap.min.css'
import MultiLogin from 'src/AdminLayouts/MultiLogin'
import 'src/Common/global.scss'
import PropTypes from 'prop-types'
import { setupInterceptors } from './Common/apiClient'
import AuthLoadedRoutes from './Routes/AuthLoadedRoutes'
import AppMentorContent from './Pages/Mentor/MentorContent.js/MentorContent'
import useSignalRPresence from './Hooks/CounselorHooks/SingleR/useSignalRPresence'
import CounselorChatBadge from './Pages/StudentPages/StdRouteBadges/CounselorChatBadge'
import { SignalRProvider } from './context/SingleRContext'

// Lazy-loaded content for specific users
const AppTutorContent = lazy(() => import('src/Pages/TutorPages/TutorContent/TutorContent'))
const AppStudentContent = lazy(() => import('src/Pages/StudentPages/StudentContent/StudentContent'))
const LibraryContent = lazy(() => import('src/Pages/LibraryPages/LibraryContent/LibraryContent'))
const AppParentContent = lazy(() => import('src/Pages/ParentPages/ParentContent/ParentContent'))
const CounselorContent = lazy(() =>
  import('src/Pages/CounselorPages/CounselorContent/CounselorContent'),
)
const AppBAMContent = lazy(() => import('src/Pages/BAMPages/BAMContent/BAMContent'))
const AdminContent = lazy(() => import('src/AdminLayouts/MultiLogin'))

const AppContent = () => {
  const userRole = localStorage.getItem('role')
  const proxyScreen = localStorage.getItem('AdminProxyScreen')

  if (userRole === 'Tutor' || (userRole === 'Admin' && proxyScreen === 'Tutor')) {
    return <AppTutorContent />
  }
  if (userRole === 'Student' || (userRole === 'Admin' && proxyScreen === 'Student')) {
    return <AppStudentContent />
  }
  if (userRole === 'Parent' || (userRole === 'Admin' && proxyScreen === 'Parent')) {
    return <AppParentContent />
  }
  if (userRole === 'Counselor' || (userRole === 'Admin' && proxyScreen === 'Counselor')) {
    return <CounselorContent />
  }
  if (userRole === 'Librarian' || (userRole === 'Admin' && proxyScreen === 'Librarian')) {
    return <LibraryContent />
  }
  if (userRole === 'BAMAdmin' || (userRole === 'Admin' && proxyScreen === 'BAMAdmin')) {
    return <AppBAMContent />
  }
  if (userRole === 'Mentor' || (userRole === 'Admin' && proxyScreen === 'Mentor')) {
    return <AppMentorContent />
  }
  if (userRole === 'Admin') {
    return <AdminContent />
  }
  return <Navigate to="/login" />
}

const Loader = ({ loading }) => {
  if (!loading) return null
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <div className="spinner-text">Loading...</div>
    </div>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired, // Ensures `loading` is a required boolean
}

const App = () => {
  const [loading, setLoading] = useState(true)
  const { unreadMessages, startPresenceHub } = useSignalRPresence()
  setupInterceptors(setLoading)
  useEffect(() => {
    const presenceStarted = localStorage.getItem('presenceHubStarted')
    if (presenceStarted === 'true') {
      startPresenceHub()
    }
  }, [startPresenceHub])

  useEffect(() => {}, [unreadMessages])

  return (
    <SignalRProvider unreadMessages={unreadMessages}>
      <BrowserRouter>
        {/* <RouteWithLoader setLoading={setLoading} /> */}
        <Suspense fallback={<Loader loading={loading} />}>
          <SoftUIControllerProvider>
            <Routes>
              <Route path="*" element={<AppContent />} />
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<AuthLoadedRoutes.Login />} />
              <Route path="/tutorLogin" element={<AuthLoadedRoutes.TutorLogin />} />
              <Route path="/multiLogin" element={<MultiLogin />} />
              <Route path="/counselorLogin" element={<AuthLoadedRoutes.CounselorLogin />} />
              <Route path="/Register" name="Register" element={<AuthLoadedRoutes.Register />} />
              <Route path="/studentregister" element={<AuthLoadedRoutes.StdRegister />} />
              <Route path="/forgotpassword" element={<AuthLoadedRoutes.ForgotPassword />} />
              <Route path="/setPassword" element={<AuthLoadedRoutes.SetPassword />} />
              <Route path="/reset-password" element={<AuthLoadedRoutes.ResetPassword />} />
              <Route path="/emailConfirmation" element={<AuthLoadedRoutes.EmailConfirmation />} />
              <Route path="/emailVerification" element={<AuthLoadedRoutes.EmailVerification />} />
            </Routes>
          </SoftUIControllerProvider>
        </Suspense>
      </BrowserRouter>
    </SignalRProvider>
  )
}

export default App

import React from 'react'
import { Card, Table, Button, Badge, Typography, Spin, Switch, Tooltip, Collapse } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { useProject } from 'src/Hooks/MentorHooks/useProjectsHooks'
import ProjectTimeline from '../table'

const { Title, Text } = Typography
const { Panel } = Collapse

const ProjectsDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { projectData, loading, updating, handleToggleResubmission } = useProject(id)

  const handleGroupConversationClick = () => {
    if (projectData?.group?.hasGroupChat && projectData?.group?.conversationId) {
      navigate('/messaging', { state: { selectedChatId: projectData.group.conversationId } })
    }
  }

  const weeklyColumns = [
    { title: 'Week', dataIndex: 'weekNumber', key: 'weekNumber' },
    { title: 'Goals', dataIndex: 'goal', key: 'goal' },
    {
      title: 'Tasks Summary',
      dataIndex: 'tasksSummary',
      key: 'tasksSummary',
      render: (tasksSummary) => (
        <div>
          {tasksSummary?.map((task, index) => (
            <div key={index} style={{ marginBottom: '5px' }}>
              ◾ {task}
            </div>
          ))}
        </div>
      ),
    },
  ]

  const taskColumns = [
    { title: 'Task', dataIndex: 'title', key: 'title' },
    {
      title: 'Assigned To',
      dataIndex: 'groupMemberId',
      key: 'groupMemberId',
      render: (id) => {
        const member = projectData?.group?.members.find((m) => m.memberId === id)
        return member ? member.studentName : 'Unknown'
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let badgeStatus, text

        switch (status) {
          case 0:
            badgeStatus = 'error'
            text = 'Pending'
            break
          case 1:
            badgeStatus = 'processing'
            text = 'In Progress'
            break
          case 2:
            badgeStatus = 'success'
            text = 'Submitted'
            break
          default:
            badgeStatus = 'default'
            text = 'Unknown'
        }

        return <Badge status={badgeStatus} text={text} />
      },
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      render: (text) => (text ? moment(text).format('DD-MM-YYYY') : 'N/A'),
    },
    {
      title: 'Submitted File',
      key: 'submittedFileUrl',
      render: (_, record) => {
        if (record.submittedFileUrl) {
          return (
            <Button
              type="link"
              onClick={() => {
                // Construct the full URL for the file
                const fileUrl = `https://soft.recipe4foodies.com${record.submittedFileUrl}`
                // Open the file in a new tab for download
                window.open(fileUrl, '_blank')
              }}
            >
              View Submitted File
            </Button>
          )
        }
        return 'No file submitted'
      },
    },
    {
      title: 'Allow Resubmission',
      key: 'enableSubmission',
      render: (_, record) => {
        const isDisabled = !record.submittedFileUrl
        return (
          <Tooltip title={isDisabled ? 'Task is not submitted yet!' : ''}>
            <Switch
              checked={record.enableSubmission}
              onChange={() => handleToggleResubmission(record.taskId, record.enableSubmission)}
              disabled={updating || isDisabled}
            />{' '}
          </Tooltip>
        )
      },
    },
  ]

  const columns = [
    {
      title: 'Group Member Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Progress Report',
      key: 'progressReport',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() =>
            navigate(`/status-report/${record.memberId}`, {
              state: { studentName: record.studentName },
            })
          }
        >
          View Reports{' '}
          {record.statusReportAlertCount > 0 && (
            <Typography.Text type="danger">({record.statusReportAlertCount})</Typography.Text>
          )}
        </Button>
      ),
    },

    {
      title: 'Issues & Challenges',
      key: 'issuesChallenges',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() =>
            navigate(`/issues-and-report/${record.memberId}`, {
              state: { studentName: record.studentName },
            })
          }
        >
          Issues & Challenges{' '}
          {record.issueAlertCount > 0 && (
            <Typography.Text type="danger">({record.issueAlertCount})</Typography.Text>
          )}
        </Button>
      ),
    },
  ]

  return (
    <Card
      className="card-professional"
      title="Project Overview"
      bordered={false}
      style={{ marginBottom: 20 }}
      extra={
        <Button type="default" onClick={() => navigate('/projects')}>
          Back
        </Button>
      }
    >
      {loading ? (
        <Spin
          size="large"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}
        />
      ) : (
        <>
          <Title level={4} style={{ marginBottom: '10px' }}>
            {projectData.title || 'N/A'}
          </Title>

          <Text style={{ display: 'block', marginBottom: '10px' }}>
            <strong>Description:</strong> {projectData.description || 'N/A'}
          </Text>

          <Text style={{ display: 'block', marginBottom: '10px' }}>
            <strong>Assigned Group:</strong> {projectData.groupName || 'N/A'}
          </Text>

          <Text style={{ display: 'block', marginBottom: '10px' }}>
            <strong>Timeline:</strong>{' '}
            {projectData.startDate ? new Date(projectData.startDate).toLocaleDateString() : 'N/A'} -{' '}
            {projectData.endDate ? new Date(projectData.endDate).toLocaleDateString() : 'N/A'}
          </Text>

          <Button type="primary" icon={<CommentOutlined />} onClick={handleGroupConversationClick}>
            Group Conversation
          </Button>
          <hr />
          <Title level={5} style={{ marginTop: 10 }}>
            Group Members:
          </Title>

          <Table
            bordered
            dataSource={projectData.group?.members || []}
            columns={columns}
            rowKey="memberId"
            pagination={true}
          />
          <hr />
          <ProjectTimeline />
          <hr />
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <Title level={3}>Weekly Plans</Title>
            </div>

            <Collapse accordion>
              {projectData.weeklyPlans?.map((week, index) => (
                <Panel
                  header={
                    <>
                      Week {week.weekNumber} (<Text strong> Goal:</Text> {week.goal} )
                    </>
                  }
                  key={index}
                >
                  <p>
                    <strong>Tasks Summary:</strong>
                  </p>
                  <ul>
                    {week.tasksSummary?.map((task, taskIndex) => (
                      <li key={taskIndex}>{task}</li>
                    ))}
                  </ul>
                </Panel>
              ))}
            </Collapse>
          </div>
          <hr />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <Title level={3} style={{ marginTop: 20 }}>
              Task Management
            </Title>
          </div>
          <Table
            columns={taskColumns}
            dataSource={projectData.tasks || []}
            pagination={true}
            bordered
          />
        </>
      )}
    </Card>
  )
}

export default ProjectsDetail

import React, { useEffect, useState } from 'react'
import { Button, Card, Typography, Select, Input, Spin, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

const { Title, Text } = Typography
const { TextArea } = Input
const { Option } = Select

const CompletedPhase = () => {
  const { phaseId, lessonLearnedId } = useParams()
  const navigate = useNavigate()
  const [dataLoading, setDataLoading] = useState(true)
  const [projectInfo, setProjectInfo] = useState(null)
  const [answers, setAnswers] = useState({})
  const [errors, setErrors] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const token = localStorage.getItem('token')

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/InternLessonLearned/phase?lessonLearnedId=${lessonLearnedId}&phaseId=${phaseId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: '*/*',
            },
          },
        )
        setProjectInfo(response.data)
      } catch (error) {
        message.error('Failed to fetch project data')
      }
      setDataLoading(false)
    }

    fetchProjectData()
  }, [phaseId, lessonLearnedId])

  // Answer change handler
  const handleAnswerChange = (question, value) => {
    setAnswers((prev) => ({
      ...prev,
      [question]: value,
    }))

    setErrors((prev) => ({
      ...prev,
      [question]: value ? '' : 'This field is required',
    }))
  }

  // Validation Check
  const validateFields = () => {
    const newErrors = {}
    projectInfo?.questions.forEach((q) => {
      if (!answers[q.question] || answers[q.question].trim() === '') {
        newErrors[q.question] = 'This field is required'
      }
    })
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  // Submit API Call
  const handleSubmit = async () => {
    if (!validateFields()) {
      message.error('Please fill all required fields!')
      return
    }

    const formattedAnswers = Object.entries(answers).map(([question, answer]) => ({
      question,
      answer,
    }))

    const payload = {
      lessonLearnedId: Number(lessonLearnedId),
      phaseId: Number(phaseId),
      answers: formattedAnswers,
    }

    try {
      setSubmitLoading(true)
      await axios.post('https://soft.recipe4foodies.com/api/InternLessonLearned/submit', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      message.success('Answers submitted successfully!')
      navigate(-1) // Navigate back
    } catch (error) {
      message.error('Failed to submit answers')
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <Card
      loading={dataLoading}
      title="📋 Student Project Evaluation Form"
      className="card-professional"
      extra={
        <Button type="default" onClick={() => navigate(-1)}>
          Back
        </Button>
      }
    >
      {projectInfo ? (
        <>
          {/* Project Information Section */}
          <div
            style={{
              backgroundColor: '#f5f5f5',
              padding: '20px',
              borderRadius: '8px',
              marginBottom: '20px',
            }}
          >
            <Title level={4}>🎓 Project Information</Title>
            <Text strong>Project Name:</Text> <Text>{projectInfo.projectName}</Text> <br />
            <Text strong>Group Name:</Text> <Text>{projectInfo.groupName}</Text> <br />
            <Text strong>Phase:</Text> <Text>{projectInfo.phaseName}</Text> <br />
          </div>

          {/* Lesson Learned Questions Section */}
          <Title level={4} style={{ marginTop: '20px' }}>
            📌 Lesson Learned Questions
          </Title>

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            {projectInfo.questions.map((question, index) => (
              <div
                key={index}
                style={{
                  flex: question.question.length < 40 ? '1 1 45%' : '1 1 100%',
                  minWidth: '300px',
                }}
              >
                <Text strong>{question.question}</Text>
                {question.isSelectableAnswer ? (
                  <Select
                    style={{ width: '100%', marginTop: '5px' }}
                    placeholder="Select an option"
                    onChange={(value) => handleAnswerChange(question.question, value)}
                    value={answers[question.question] || undefined}
                  >
                    {question.answerOptions.map((option, i) => (
                      <Option key={i} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <TextArea
                    rows={3}
                    placeholder="Enter your response..."
                    style={{ marginTop: '5px' }}
                    onChange={(e) => handleAnswerChange(question.question, e.target.value)}
                    value={answers[question.question] || ''}
                  />
                )}
                {errors[question.question] && (
                  <Text type="danger">{errors[question.question]}</Text>
                )}
              </div>
            ))}
          </div>

          {/* Submit Button */}
          <Button
            type="primary"
            style={{ marginTop: '20px' }}
            onClick={handleSubmit}
            disabled={submitLoading}
            loading={submitLoading}
          >
            {submitLoading ? 'Please wait...' : 'Submit'}
          </Button>
        </>
      ) : (
        <Text type="danger">No project data found.</Text>
      )}
    </Card>
  )
}

export default CompletedPhase

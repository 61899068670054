import React, { useRef, useState } from 'react'
import {
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FileOutlined,
} from '@ant-design/icons'
import axios from 'axios'

const ChatFileHandler = (
  selectedChatId,
  sendMessage,
  handleFileUpload,
  setSelectedChatId,
  hubConnection,
) => {
  const token = localStorage.getItem('token')
  const [file, setFile] = useState(null)
  const fileInputRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pendingAction, setPendingAction] = useState(null)
  const chatMessagesRef = useRef(null)
  const userScrolledUp = useRef(false)
  const [messageText, setMessageText] = useState('')
  // Function to get file icon based on file type
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return <FilePdfOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
      case 'doc':
      case 'docx':
        return <FileWordOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
      case 'xls':
      case 'xlsx':
        return <FileExcelOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
      case 'csv':
        return <FileTextOutlined style={{ fontSize: '24px', color: '#faad14' }} />
      default:
        return <FileOutlined style={{ fontSize: '24px', color: '#8c8c8c' }} />
    }
  }

  // Function to format file size
  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
  }

  const handleDownload = async (messageId, fileName) => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Chats/Download-Message-Attachment?messageId=${messageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: '*/*',
          },
          responseType: 'blob',
        },
      )

      // Create a link element and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName || 'file') // Default filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Download failed:', error)
    }
  }

  const handleRemoveFile = () => {
    if (file) {
      setPendingAction(() => () => {
        setFile(null) // Clear the file state
        if (fileInputRef.current) {
          fileInputRef.current.value = '' // Reset the file input
        }
      })
      setIsModalVisible(true)
    }
  }

  const handleModalConfirm = () => {
    if (pendingAction) {
      pendingAction(selectedChatId) // Execute the pending action
    }
    setIsModalVisible(false)
    setPendingAction(null)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
    setPendingAction(null)
  }

  const handleScroll = () => {
    if (chatMessagesRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatMessagesRef.current
      userScrolledUp.current = scrollTop + clientHeight < scrollHeight - 50
    }
  }

  const handleSendMessage = () => {
    if (messageText.trim() !== '' || file) {
      sendMessage(selectedChatId, messageText, file)
      setMessageText('')
      setFile(null) // Clear the file after sending
      userScrolledUp.current = false
    }
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    if (selectedFile && handleFileUpload(selectedFile)) {
      setFile(selectedFile) // Set the selected file
    } else {
      setFile(null) // Clear the file if it's invalid
    }
  }

  const handleCloseChat = async () => {
    if (file) {
      setPendingAction(() => async () => {
        if (hubConnection) {
          try {
            await hubConnection.invoke('RemoveActiveConversation')
            console.log('✅ Active conversation removed')
          } catch (error) {
            console.error('⚠️ Error removing active conversation:', error)
          }
        }
        setSelectedChatId(null)
      })
      setIsModalVisible(true)
    } else {
      if (hubConnection) {
        try {
          await hubConnection.invoke('RemoveActiveConversation')
          console.log('✅ Active conversation removed')
        } catch (error) {
          console.error('⚠️ Error removing active conversation:', error)
        }
      }
      setSelectedChatId(null)
    }
  }

  return {
    file,
    setFile,
    fileInputRef,
    getFileIcon,
    formatFileSize,
    handleDownload,
    isModalVisible,
    setIsModalVisible,
    setPendingAction,
    handleRemoveFile,
    handleModalConfirm,
    handleModalCancel,
    handleScroll,
    handleSendMessage,
    handleFileChange,
    handleCloseChat,
    userScrolledUp,
    chatMessagesRef,
    messageText,
    setMessageText,
  }
}

export default ChatFileHandler

import React from 'react'
import { Table, Button, Card } from 'antd'
import { useNavigate } from 'react-router-dom'
import useLessonLearned from 'src/Hooks/MentorHooks/useLessonLearned'

const LessonLearned = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  const { data, loading } = useLessonLearned(userId, token)

  const columns = [
    {
      title: 'Project Id',
      dataIndex: 'projectId',
      key: 'projectId',
    },
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: 'Completed Phase',
      dataIndex: 'completedPhase',
      key: 'completedPhase',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => navigate(`/completed-phase/${record.lessonLearnedId}/${record.phaseId}`)}
        >
          Proceed
        </Button>
      ),
    },
  ]

  return (
    <Card
      loading={loading}
      title="Lesson Learned"
      className="card-professional"
      extra={
        <Button type="default" onClick={() => navigate('/projects')}>
          Back
        </Button>
      }
    >
      <Table columns={columns} dataSource={data} pagination={false} />
    </Card>
  )
}

export default LessonLearned

import React from 'react'
import PropTypes from 'prop-types'
import { BadgeProvider } from './MentorContext/BadgeContext'

const AppMentorProviders = ({ children }) => <BadgeProvider>{children}</BadgeProvider>

AppMentorProviders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppMentorProviders

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Button, Dropdown, Input, Menu, Modal, Typography } from 'antd'
import { PaperClipOutlined, CloseOutlined, TeamOutlined } from '@ant-design/icons'
import defaultImg from 'src/assets/images/p.png'
import FileIcon from './useChatFileHandler'
const { Text } = Typography

const ChatWindow = ({
  handleFileUpload,
  setSelectedChatId,
  hubConnection,
  fetchChatDetails,
  chatDetails,
  chatMessages = [],
  selectedChatId,
  sendMessage,
  setChatDetails,
  setChatMessages,
  loading,
  msgLoader,
}) => {
  const messagesEndRef = useRef(null)
  const {
    file,
    setFile,
    fileInputRef,
    getFileIcon,
    formatFileSize,
    handleDownload,
    isModalVisible,
    setIsModalVisible,
    setPendingAction,
    handleRemoveFile,
    handleModalConfirm,
    handleModalCancel,
    handleScroll,
    handleSendMessage,
    handleFileChange,
    handleCloseChat,
    userScrolledUp,
    chatMessagesRef,
    messageText,
    setMessageText,
  } = FileIcon(selectedChatId, sendMessage, handleFileUpload, setSelectedChatId, hubConnection)
  const userId = Number(localStorage.getItem('userId'))
  // Effect to handle switching chats
  useEffect(() => {
    if (file && selectedChatId) {
      setPendingAction(() => (newChatId) => {
        setSelectedChatId(newChatId) // Switch to the new chat
        setFile(null) // Clear the file
        if (fileInputRef.current) {
          fileInputRef.current.value = '' // Reset the file input
        }
      })
      setIsModalVisible(true) // Show the modal
    }
  }, [selectedChatId]) // Triggered when selectedChatId changes

  useEffect(() => {
    if (selectedChatId) {
      fetchChatDetails(selectedChatId)
      if (hubConnection) {
        hubConnection
          .invoke('SetActiveConversation', selectedChatId)
          .then(() => console.log('✅ Active conversation set:', selectedChatId))
          .catch((error) => console.error('⚠️ Error setting active conversation:', error))
      }
    } else {
      setChatDetails(null)
      setChatMessages([])
    }
  }, [selectedChatId, hubConnection])

  useEffect(() => {
    // Auto-scroll only if the user hasn't scrolled up
    if (!userScrolledUp.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatMessages])

  const groupMembers = chatDetails?.members || []

  const groupMembersMenu = (
    <Menu>
      {groupMembers?.map((member) => (
        <Menu.Item key={member.id} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Avatar
            src={
              member.profileUrl ? `https://soft.recipe4foodies.com${member.profileUrl}` : defaultImg
            }
            alt={member.name}
            size={32}
          />

          <div style={{ marginLeft: '10px' }}>
            {' '}
            {/* Move Name & Role Forward */}
            <Text strong style={{ display: 'block', fontSize: '14px' }}>
              {member.name}{' '}
              {member.userId === userId && (
                <span style={{ color: 'green', fontWeight: 'bold' }}>(You)</span>
              )}
            </Text>
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {member.role}
            </Text>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return
      }
      if (!loading) {
        e.preventDefault()
        handleSendMessage()
        setMessageText('')
      }
    }
  }

  return (
    <div className="chat-section">
      <div className="chat-header">
        {chatDetails ? (
          chatDetails.type === 'group' ? (
            <>
              {/* Check if the logged-in user is a Mentor */}
              {chatDetails.members.some((member) => member.role === 'Mentor') && (
                <Dropdown overlay={groupMembersMenu} trigger={['click']} placement="bottomLeft">
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      float: 'left',
                    }}
                  >
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#226c87',
                        fontSize: '18px',
                      }}
                    >
                      {/* <Avatar
                        style={{ backgroundColor: '#226c87', color: '#fff' }}
                        icon={<TeamOutlined />}
                      /> */}
                      <TeamOutlined />
                    </div>
                  </div>
                </Dropdown>
              )}
              Group Chat
            </>
          ) : (
            `Chat with ${chatDetails.name}`
          )
        ) : (
          'Loading chat details...'
        )}
        <Button type="default" onClick={handleCloseChat} style={{ float: 'right' }}>
          Close Chat
        </Button>
      </div>

      <div className="chat-messages" ref={chatMessagesRef} onScroll={handleScroll}>
        {msgLoader ? (
          <div className="loading-container">
            <p>⏳ Please wait...</p>
          </div>
        ) : chatMessages.length > 0 ? (
          chatMessages.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.isSentByUser ? 'user-message' : 'student-message'}`}
              style={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}
            >
              {!msg.isSentByUser && (
                <div className="profile-container">
                  <img
                    src={`https://soft.recipe4foodies.com${msg.senderProfile}`}
                    alt=""
                    className="profile-pic"
                    onError={(e) => {
                      e.target.src = defaultImg
                      e.target.onerror = null
                    }}
                  />
                </div>
              )}

              <div className="message-content" style={{ whiteSpace: 'pre-line' }}>
                {!msg.isSentByUser && (
                  <div
                    className="sender-name"
                    style={{ fontWeight: 'bold', marginBottom: '5px', color: '#0c6157' }}
                  >
                    {msg.senderName}
                  </div>
                )}
                {msg.messageType === 2 && (
                  <div className="doc-file">
                    {/* PDF Icon & Name in One Row */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <div className="doc-file-icon">{getFileIcon(msg.attachmentName)}</div>

                      <div>
                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
                          {msg.attachmentName}
                        </div>
                        <div style={{ fontSize: '12px', color: '#8c8c8c' }}>
                          {formatFileSize(msg.attachmentSize || 0)}
                        </div>
                      </div>
                    </div>

                    {/* Horizontal Line */}
                    <hr style={{ margin: '10px 0', border: '0.5px solid #e0e0e0' }} />

                    {/* Action Buttons */}
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                      <Button
                        type="default"
                        onClick={() => handleDownload(msg.messageId, msg.attachmentName)}
                      >
                        Download
                      </Button>
                    </div>
                  </div>
                )}
                {msg.text}
              </div>
            </div>
          ))
        ) : (
          <div className="no-messages">
            <p>💬 Start a New Conversation</p>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-input" style={{ position: 'relative' }}>
        {file && (
          <div className="selected-file">
            {getFileIcon(file.name)}
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <div className="selected-file-name">{file.name}</div>
              <div style={{ fontSize: '12px', color: '#8c8c8c' }}>{formatFileSize(file.size)}</div>
            </div>
            <CloseOutlined
              style={{ cursor: 'pointer', color: '#ff4d4f' }}
              onClick={handleRemoveFile}
            />
          </div>
        )}
        {chatDetails?.canUploadAttachment && ( // Check if canUploadAttachment is true
          <>
            <label htmlFor="file-upload" style={{ cursor: 'pointer', marginRight: '10px' }}>
              <PaperClipOutlined style={{ fontSize: '20px' }} />
            </label>
            <input
              id="file-upload"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.xls,.xlsx,.csv"
              ref={fileInputRef} // Attach the ref to the file input
            />
          </>
        )}
        <Input.TextArea
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Type a message..."
          autoSize={{ minRows: 2, maxRows: 2 }}
          style={{ overflowY: 'auto' }}
          onKeyDown={handleKeyDown}
        />

        <Button
          type="primary"
          disabled={loading}
          style={{ height: '40px' }}
          onClick={handleSendMessage}
          loading={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </Button>
      </div>

      <Modal
        title="Discard File"
        visible={isModalVisible}
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to discard the selected file?</p>
      </Modal>
    </div>
  )
}

ChatWindow.propTypes = {
  selectedChatId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectedChatId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sendMessage: PropTypes.func.isRequired,
  fetchChatDetails: PropTypes.func.isRequired,
  chatMessages: PropTypes.array.isRequired,
  setChatMessages: PropTypes.func.isRequired,
  setChatDetails: PropTypes.func.isRequired,
  chatDetails: PropTypes.object,
  handleFileUpload: PropTypes.func,
  hubConnection: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  msgLoader: PropTypes.bool.isRequired,
}

ChatWindow.defaultProps = {
  messages: [],
}

export default ChatWindow

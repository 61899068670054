import React, { useEffect } from 'react'
import { Card, Input, List, Modal } from 'antd'
import 'src/Common/ChatApp.scss'
import Sidebar from './ChatSidebar'
import ChatWindow from './ChatWindow'
import useCounselorChat from './useCounselorChat'
import useSignalRPresence from 'src/Hooks/CounselorHooks/SingleR/useSignalRPresence'
import { useLocation } from 'react-router-dom'

const StartChat = () => {
  const { unreadMessages } = useSignalRPresence()
  const {
    handleFileUpload,
    hubConnection,
    userId,
    token,
    students,
    groupChat,
    groups,
    tutors,
    counselors,
    activeChats,
    isModalVisible,
    loading,
    mentorModelVisible,
    isGroupModalVisible,
    isTutorModalVisible,
    chatMessages,
    chatDetails,
    startChat,
    startGroupChat,
    sendMessage,
    hoveredId,
    setHoveredId,
    search,
    setSearch,
    modelSearch,
    setModelSearch,
    setIsModalVisible,
    setMentorModelVisible,
    setIsGroupModalVisible,
    setIsTutorModalVisible,
    setSelectedChatId,
    selectedChatId,
    fetchChatDetails,
    setChatDetails,
    setChatMessages,
    loader,
    conversations,
    fetchConversations,
    msgLoader,
    role,
    startMentorGroupChat,
  } = useCounselorChat()
  const location = useLocation()
  useEffect(() => {
    if (location.state?.selectedChatId) {
      setSelectedChatId(location.state.selectedChatId)
    }
  }, [location.state])

  return (
    <Card className="card-professional" title="Chat">
      <div className="chat-container">
        <Sidebar
          activeChats={activeChats}
          search={search}
          setSearch={setSearch}
          setIsModalVisible={setIsModalVisible}
          setIsGroupModalVisible={setIsGroupModalVisible}
          setIsTutorModalVisible={setIsTutorModalVisible}
          setMentorModelVisible={setMentorModelVisible}
          setSelectedChatId={setSelectedChatId}
          selectedChatId={selectedChatId}
          hoveredId={hoveredId}
          setHoveredId={setHoveredId}
          userId={userId}
          token={token}
          loader={loader}
          conversations={conversations}
          fetchConversations={fetchConversations}
          unreadMessages={unreadMessages}
        />

        <div className="chat-section">
          {selectedChatId ? (
            <>
              <ChatWindow
                handleFileUpload={handleFileUpload}
                setSelectedChatId={setSelectedChatId}
                hubConnection={hubConnection}
                selectedChatId={selectedChatId}
                sendMessage={sendMessage}
                userId={Number(userId)}
                token={token}
                fetchChatDetails={fetchChatDetails}
                chatDetails={chatDetails}
                chatMessages={chatMessages || []}
                setChatDetails={setChatDetails}
                setChatMessages={setChatMessages}
                loading={loading}
                msgLoader={msgLoader}
                conversations={conversations}
              />
            </>
          ) : (
            <div className="chat-header2">Click New Chat / Group Chat to start a conversation</div>
          )}
        </div>

        <Modal
          title={role === 'Parent' || role === 'Tutor' ? 'Select a Counselor' : 'Select a Student'}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Input
            placeholder="Search..."
            value={modelSearch}
            onChange={(e) => setModelSearch(e.target.value)}
            style={{ marginBottom: '10px' }}
          />

          {/* Parent & Tutor -> Counselors list show */}
          {role === 'Parent' ? (
            <List
              bordered
              dataSource={counselors.filter((counselor) =>
                counselor.name.toLowerCase().includes(modelSearch.toLowerCase()),
              )}
              renderItem={(counselor) => (
                <List.Item onClick={() => startChat(counselor)} style={{ cursor: 'pointer' }}>
                  {counselor.name}
                </List.Item>
              )}
            />
          ) : (
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <List
                bordered
                dataSource={students.filter((student) =>
                  student.name.toLowerCase().includes(modelSearch.toLowerCase()),
                )}
                renderItem={(student) => (
                  <List.Item
                    onClick={() => {
                      console.log('Selected student:', student)
                      startChat(student)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {student.name}
                  </List.Item>
                )}
              />
            </div>
          )}
        </Modal>

        <Modal
          title="Select a Tutor"
          open={isTutorModalVisible}
          onCancel={() => setIsTutorModalVisible(false)}
          footer={null}
        >
          <Input
            placeholder="Search..."
            value={modelSearch}
            onChange={(e) => setModelSearch(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <List
              bordered
              dataSource={tutors.filter((tutor) =>
                tutor.name.toLowerCase().includes(modelSearch.toLowerCase()),
              )}
              renderItem={(tutor) => (
                <List.Item onClick={() => startChat(tutor)} style={{ cursor: 'pointer' }}>
                  {tutor.name}
                </List.Item>
              )}
            />
          </div>
        </Modal>

        <Modal
          title="Select Students to create a group"
          open={mentorModelVisible}
          onCancel={() => setMentorModelVisible(false)}
          footer={null}
        >
          <Input
            placeholder="Search..."
            value={modelSearch}
            onChange={(e) => setModelSearch(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <List
              bordered
              dataSource={groups.filter((mentorGroup) =>
                mentorGroup.groupName.toLowerCase().includes(modelSearch.toLowerCase()),
              )}
              renderItem={(mentorGroup) => (
                <List.Item
                  onClick={() => {
                    startMentorGroupChat(mentorGroup)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {mentorGroup.groupName}
                </List.Item>
              )}
            />
          </div>
        </Modal>

        <Modal
          title="Select Students and Parents for Group Chat"
          open={isGroupModalVisible}
          onCancel={() => setIsGroupModalVisible(false)}
          footer={null}
        >
          <Input
            placeholder="Search..."
            value={modelSearch}
            onChange={(e) => setModelSearch(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <List
              bordered
              dataSource={groupChat.filter((group) =>
                group.name.toLowerCase().includes(modelSearch.toLowerCase()),
              )}
              renderItem={(student) => (
                <List.Item onClick={() => startGroupChat(student)} style={{ cursor: 'pointer' }}>
                  {role === 'Parent' && student.counselorName
                    ? `${student.name} (Counselor: ${student.counselorName})`
                    : student.name}
                </List.Item>
              )}
            />
          </div>
        </Modal>
      </div>
    </Card>
  )
}

export default StartChat

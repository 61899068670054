import apiClient from 'src/Common/apiClient'

export const fetchData = async (id, token, completed) => {
  try {
    const response = await apiClient.get(
      `/InternshipProject/Mentor/Projects?mentorId=${id}&completed=${completed}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    return response.data
  } catch (error) {
    console.error('Error fetching project data:', error)
    throw error
  }
}

export const fetchProjectData = async (id, token) => {
  try {
    const response = await apiClient.get(`/InternshipProject/Mentor/Projects/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching project data:', error)
    throw error
  }
}

export const assignTask = async (taskData, token) => {
  try {
    const response = await apiClient.post(`/InternshipProject/Mentor/AssignTask`, taskData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return response.data
  } catch (error) {
    console.error('Error assigning task:', error)
    throw error
  }
}

export const toggleResubmission = async (taskId, token) => {
  try {
    const response = await apiClient.post(
      `/InternshipProject/Toggle-Resubmission?taskId=${taskId}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    )
    return response.data // API success response
  } catch (error) {
    console.error('Error toggling resubmission:', error)
    throw error
  }
}

export const fetchReports = async (memberId, token) => {
  try {
    const response = await apiClient.get(`/InternshipStatusReport/student?memberId=${memberId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching reports:', error)
    throw error
  }
}

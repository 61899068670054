import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types' // Import PropTypes
import 'antd/dist/reset.css'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import predefinedStudentColors from 'src/Common/predefinedStudentColors'

const ProjectTimeline = () => {
  const token = localStorage.getItem('token')
  const [data, setData] = useState([])
  const { id } = useParams()

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/InternshipProject/Mentor/Projects/${id}`,
          {
            headers: {
              accept: '*/*',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        const weeklyPlans = response.data.weeklyPlansTable
        const formattedData = formatData(weeklyPlans)
        setData(formattedData)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [id, token])

  // Format the API data into the required table structure
  const formatData = (weeklyPlans) => {
    return weeklyPlans.map((plan, index) => {
      const row = {
        key: index.toString(),
        stage: plan.stepTitle,
      }

      // Assign weeks based on the `week` array
      plan.week.forEach((week) => {
        row[`week${week}`] = {
          performer: plan.performer,
          color: predefinedStudentColors(index),
        }
      })

      return row
    })
  }

  // Define the columns for the table
  const columns = [
    {
      title: 'Project Briefing',
      dataIndex: 'stage',
      key: 'stage',
      render: (text) => <strong style={{ paddingLeft: '15px' }}>{text}</strong>,
    },
    ...Array.from({ length: 6 }, (_, index) => {
      const week = `week${index + 1}`
      return {
        title: `Week ${index + 1}`,
        dataIndex: week,
        key: week,
        render: (value, record, rowIndex) => {
          if (!value) {
            // If no value, return an empty cell
            return {
              children: <div style={{ minHeight: '50px' }} />,
              props: {
                colSpan: 1,
              },
            }
          }

          const { performer, color } = value

          // Check if the current cell is the first in the group of same color
          let isFirstCell = true
          let colSpan = 1

          if (index > 0) {
            const prevWeek = `week${index}`
            const prevValue = record[prevWeek]
            if (prevValue && prevValue.color === color) {
              isFirstCell = false
            }
          }

          // Calculate colspan for the current cell
          if (isFirstCell) {
            for (let i = index + 1; i < 6; i++) {
              const nextWeek = `week${i + 1}`
              const nextValue = record[nextWeek]
              if (nextValue && nextValue.color === color) {
                colSpan++
              } else {
                break
              }
            }
          } else {
            // If not the first cell, hide it (colSpan = 0)
            return {
              props: {
                colSpan: 0,
              },
            }
          }

          return {
            children: (
              <div
                style={{
                  backgroundColor: color,
                  textAlign: 'center',
                  minHeight: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  padding: '0',
                  color: 'white',
                }}
              >
                {performer}
              </div>
            ),
            props: {
              colSpan: colSpan,
            },
          }
        },
      }
    }),
  ]

  // Custom cell component to override default td
  const CustomCell = ({ style, ...props }) => (
    <td
      {...props}
      style={{
        ...style,
        padding: 0, // Remove padding to ensure seamless collapse
      }}
    />
  )

  // Add prop-types validation for CustomCell
  CustomCell.propTypes = {
    style: PropTypes.object, // Validate the style prop
  }

  return (
    <div style={{ padding: '20px' }}>
      <Table
        columns={columns}
        dataSource={data}
        bordered={true}
        pagination={false}
        size="middle"
        style={{ borderCollapse: 'collapse' }} // Remove space between cells
        components={{
          body: {
            cell: CustomCell, // Use the custom cell component
          },
        }}
      />
    </div>
  )
}

export default ProjectTimeline

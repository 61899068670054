import apiClient from 'src/Common/apiClient'

export const fetchLessonLearnedData = async (userId, token) => {
  try {
    const response = await apiClient.get(`/InternLessonLearned/pending/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: '*/*',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching lesson learned data:', error)
    throw error
  }
}

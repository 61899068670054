import React, { useEffect, useState } from 'react'
import { Card, Table } from 'antd'
import axios from 'axios'

const AssignedStudents = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const [assignedStudents, setAssignedStudents] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchMentorGroups()
  }, [])

  const fetchMentorGroups = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/InternshipGroup/Student/List?mentorId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: '*/*',
          },
        },
      )
      setAssignedStudents(response.data)
    } catch (error) {
      console.error('Error fetching mentor groups:', error)
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Academic Year',
      dataIndex: 'academicYear',
      key: 'academicYear',
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => new Date(text).toLocaleDateString(),
    },
  ]

  return (
    <>
      <Card
        loading={loading}
        className="card-professional"
        title="Assigned Students & Projects"
        style={{ marginTop: 20 }}
      >
        <Table dataSource={assignedStudents} columns={columns} bordered rowKey="groupId" />
      </Card>
    </>
  )
}

export default AssignedStudents

import { useState } from 'react'
import useFetchApiData from './GetStudentList'
import useSignalRChat from 'src/Hooks/CounselorHooks/SingleR/useSignalRChat'
import { message } from 'antd'

const useCounselorChat = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const role = localStorage.getItem('role')
  const { students, groupChat, tutors, counselors, groups } = useFetchApiData()
  const [activeChats, setActiveChats] = useState([])
  const [selectedChatId, setSelectedChatId] = useState(null)
  const [search, setSearch] = useState('')
  const [modelSearch, setModelSearch] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isGroupModalVisible, setIsGroupModalVisible] = useState(false)
  const [mentorModelVisible, setMentorModelVisible] = useState(false)
  const [isTutorModalVisible, setIsTutorModalVisible] = useState(false)
  const [hoveredId, setHoveredId] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState([])
  const [chatMessages, setChatMessages] = useState([])
  const [chatDetails, setChatDetails] = useState(null)
  const [conversations, setConversations] = useState([])
  const [loader, setLoader] = useState(true)
  const [msgLoader, setMsgLoader] = useState(true)
  const [hubConnection, setHubConnection] = useState(null)

  //Single R
  useSignalRChat(setChatMessages, setConversations, selectedChatId, setLoader, setHubConnection)

  const startChat = async (student) => {
    try {
      const payload =
        role === 'Student'
          ? { requesterId: student.id, receiverId: Number(userId) }
          : { requesterId: Number(userId), receiverId: student.id }

      const response = await fetch('https://soft.recipe4foodies.com/api/Chats/private/Create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        const errorText = await response.text()
        console.error('API Error Response:', errorText)
        throw new Error(`Failed to start chat: ${errorText}`)
      }

      const data = await response.json()

      if (!data.conversationId) {
        throw new Error('Missing conversationId in response')
      }

      const newChat = {
        id: data.conversationId,
        name: student.name,
        type: 'single',
        messages: [],
      }

      setActiveChats((prevChats) => {
        if (!prevChats.some((chat) => chat.id === newChat.id)) {
          return [...prevChats, newChat]
        }
        return prevChats
      })

      setSelectedChatId(newChat.id)
      setIsModalVisible(false)
      setIsTutorModalVisible(false)
    } catch (error) {
      console.error('Error starting chat:', error)
    }
  }

  const startGroupChat = async (student) => {
    try {
      const payload =
        role === 'Parent'
          ? { counselorId: student.counselorId, studentId: student.id }
          : { counselorId: Number(userId), studentId: student.id }
      const response = await fetch(
        'https://soft.recipe4foodies.com/api/Chats/group/student-parent',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        },
      )
      if (!response.ok) {
        const errorText = await response.text()
        console.error('API Error Response:', errorText)
        throw new Error(`Failed to start chat: ${errorText}`)
      }

      const data = await response.json()

      const newChat = {
        id: data.conversationId,
        name: student.name,
        type: 'group',
        messages: [],
      }

      setActiveChats((prevChats) => {
        if (!prevChats.some((chat) => chat.id === newChat.id)) {
          return [...prevChats, newChat]
        }
        return prevChats
      })

      setSelectedChatId(newChat.id)
      setIsGroupModalVisible(false)
    } catch (error) {
      console.error('Error starting chat:', error)
    }
  }

  const startMentorGroupChat = async (mentorGroup) => {
    try {
      const payload = {
        requesterId: Number(userId),
        mentorId: Number(userId),
        groupId: mentorGroup.groupId,
      }

      const response = await fetch(
        'https://soft.recipe4foodies.com/api/Chats/mentor/group/Create',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        },
      )

      if (!response.ok) {
        const errorText = await response.text()
        console.error('API Error Response:', errorText)
        throw new Error(`Failed to start chat: ${errorText}`)
      }

      const data = await response.json()

      const newChat = {
        id: data.conversationId,
        name: mentorGroup.name,
        type: 'group',
        messages: [],
      }

      setActiveChats((prevChats) => {
        if (!prevChats.some((chat) => chat.id === newChat.id)) {
          return [...prevChats, newChat]
        }
        return prevChats
      })

      setSelectedChatId(newChat.id)

      setMentorModelVisible(false)
    } catch (error) {
      console.error('Error starting chat:', error)
    }
  }

  const sendMessage = async (chatId, message, file = null) => {
    try {
      setLoading(true)

      const formData = new FormData()
      formData.append('senderId', userId)
      formData.append('conversationId', chatId)
      formData.append('content', message || '') // Ensure empty message is handled
      formData.append('messageType', file ? 2 : 1)

      if (file) {
        formData.append('attachment', file, file.name)
      }

      // // Debugging: Check what data is being sent
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value)
      // }

      const response = await fetch('https://soft.recipe4foodies.com/api/Chats/Send-messages', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })

      if (!response.ok) {
        const errorData = await response.json()
        console.error('Server Response Error:', errorData)
        throw new Error(errorData.message || 'Failed to send message')
      }

      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.conversationId === chatId ? { ...conv, chatSubTitle: message || file.name } : conv,
        ),
      )
      fetchChatDetails(chatId)
    } catch (error) {
      console.log('error', error)
      console.error('Error sending message:', error)
    } finally {
      setLoading(false)
    }
  }

  const allowedExtensions = [
    'pdf',
    'PDF',
    'doc',
    'DOC',
    'docx',
    'DOCX',
    'xls',
    'XLS',
    'xlsx',
    'XLSX',
    'CSV',
    'csv',
  ]
  const allowedMimeTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ]

  const maxFileSize = 10 * 1024 * 1024 // 10MB

  const handleFileUpload = (file) => {
    if (!file || !file.name) {
      message.error('Invalid file. Please upload a valid file.')
      return false
    }

    // Extract file extension safely
    const fileExtension = file.name.split('.').pop().toLowerCase()

    // Check both extension and MIME type
    if (!allowedExtensions.includes(fileExtension) && !allowedMimeTypes.includes(file.type)) {
      message.error('Invalid file type. Allowed types: PDF, DOC, DOCX, XLS, XLSX, CSV')
      return false
    }

    if (file.size > maxFileSize) {
      message.error('File size exceeds 10MB limit')
      return false
    }

    return true
  }

  const fetchChatDetails = async (conversationId) => {
    try {
      const response = await fetch(
        `https://soft.recipe4foodies.com/api/Chats/Get-Messages?conversationId=${conversationId}&userId=${userId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (!response.ok) {
        throw new Error('Failed to fetch chat details')
      }

      const data = await response.json()
      const canUploadAttachment = data.canUploadAttachment ?? false
      const formattedMessages = data.messages.map((msg) => {
        const sender = data.members.find((member) => member.userId === msg.senderId)

        return {
          text: msg.content,
          messageId: msg.messageId,
          attachmentUrl: msg.attachmentUrl,
          attachmentName: msg.attachmentName,
          messageType: msg.messageType,
          senderId: msg.senderId,
          senderName: sender ? sender.name : 'Unknown',
          senderProfile: sender ? sender.profileUrl : '',
          isSentByUser: msg.isSentByUser,
        }
      })

      setChatDetails({
        id: data.conversationId,
        name: data.chatTitle,
        type: data.chatType === 2 ? 'group' : 'single',
        members: data.members || [],
        canUploadAttachment,
      })
      setChatMessages(formattedMessages)
      if (hubConnection) {
        await hubConnection.invoke('SetActiveConversation', conversationId)
        // console.log('✅ Active conversation set:', conversationId)
      }
    } catch (error) {
      console.error('Error fetching chat details:', error)
    } finally {
      setMsgLoader(false)
    }
  }

  return {
    handleFileUpload,
    hubConnection,
    userId,
    token,
    students,
    groupChat,
    groups,
    tutors,
    counselors,
    activeChats,
    isModalVisible,
    loading,
    mentorModelVisible,
    isGroupModalVisible,
    isTutorModalVisible,
    setSelectedGroup,
    chatMessages,
    chatDetails,
    startChat,
    startGroupChat,
    sendMessage,
    hoveredId,
    setHoveredId,
    search,
    setSearch,
    modelSearch,
    setModelSearch,
    setIsModalVisible,
    setMentorModelVisible,
    setIsGroupModalVisible,
    setIsTutorModalVisible,
    setSelectedChatId,
    selectedChatId,
    fetchChatDetails,
    setChatDetails,
    setChatMessages,
    selectedGroup,
    loader,
    conversations,
    msgLoader,
    role,
    startMentorGroupChat,
  }
}

export default useCounselorChat

import React from 'react'
import { Form, Input, DatePicker, Select, Button, Upload, message, Col, Row, Card } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { USStates } from 'src/Common/USStates'
import { AllCountries } from 'src/Common/AllCountries'
import useMentorProfile from 'src/Hooks/MentorHooks/useMentorProfile'

const { Option } = Select

const ProfileForm = () => {
  const {
    form,
    previewImage,
    loading,
    loader,
    handleSubmit,
    handleCountryChange,
    isUS,
    setPreviewImage,
    initialValues,
  } = useMentorProfile()

  return (
    <Card loading={loading} className="card-professional" title="Profile">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
        encType="multipart/form-data"
      >
        <Form.Item label="Profile Image" name="profileImageFile" valuePropName="file">
          <Upload
            name="profileImageFile"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={(file) => {
              if (!file.type.startsWith('image/')) {
                message.error('Please select an image file')
                return false
              }
              if (file.size > 5 * 1024 * 1024) {
                message.error('File size must be less than 5MB')
                return false
              }
              return true
            }}
            onChange={({ file }) => {
              if (file.status === 'done') {
                setPreviewImage(URL.createObjectURL(file.originFileObj))
              } else if (file.status === 'error') {
                message.error(`${file.name} file upload failed.`)
              }
              form.setFieldsValue({
                profileImageFile: file.status === 'removed' ? null : file,
              })
            }}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => onSuccess('ok'), 0)
            }}
          >
            {previewImage ? (
              <img
                src={previewImage}
                alt="Selected Avatar"
                style={{
                  height: '100px',
                  width: '100px',
                  cursor: 'pointer',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <div>
                <UploadOutlined />
                <div>Click to upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'First Name is required' }]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Middle Name" name="middleName">
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Last Name is required' }]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Email" name="email">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Date of Birth"
              name="dateOfBirth"
              rules={[{ required: true, message: 'Date of Birth is required' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <Select placeholder="Select Gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="UnSpecified">UnSpecified</Option>
                <Option value="Do not wish to disclosed">Do not wish to disclosed</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Expertise Area"
              name="expertiseArea"
              rules={[{ required: true, message: 'Expertise Area is required' }]}
            >
              <Select placeholder="Select Expertise Area">
                <Option value={1}>Data Analysis</Option>
                <Option value={2}>Data Modeling</Option>
                <Option value={3}>Content Creation</Option>
                <Option value={4}>Go to Market</Option>
                <Option value={5}>Design Elements</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: 'State is required' }]}
            >
              {isUS ? (
                <Select placeholder="Select State">
                  {USStates.map(({ value, label }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input placeholder="Enter State" />
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Country is required' }]}
            >
              <Select
                placeholder="Select Country"
                onChange={handleCountryChange}
                showSearch
                filterOption={(input, option) => {
                  const label = option.children.toLowerCase()
                  return label.includes(input.toLowerCase())
                }}
              >
                {AllCountries.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" loading={loader} htmlType="submit" disabled={loader}>
            {loader ? 'Please Wait...' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default ProfileForm

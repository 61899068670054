import apiClient from 'src/Common/apiClient'

const token = localStorage.getItem('token')

export const markTaskAsStarted = async (memberId, taskId) => {
  try {
    const response = await apiClient.post(
      '/InternshipProject/Mark-As-Started',
      {
        memberId,
        taskId,
      },
      {
        headers: {
          accept: '*/*',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    return response.data
  } catch (error) {
    console.error('Error starting task:', error)
    throw error
  }
}

export const submitTask = async (memberId, taskId, file) => {
  const formData = new FormData()
  formData.append('memberId', memberId)
  formData.append('taskId', taskId)
  formData.append('file', file)

  try {
    const response = await apiClient.post('/InternshipProject/Submit-Task', formData, {
      headers: {
        accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error submitting task:', error)
    throw error
  }
}

export const reSubmitTask = async (memberId, taskId, file) => {
  const formData = new FormData()
  formData.append('memberId', memberId)
  formData.append('taskId', taskId)
  formData.append('file', file)

  try {
    const response = await apiClient.post('/InternshipProject/Resubmit-Task', formData, {
      headers: {
        accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error submitting task:', error)
    throw error
  }
}

export const fetchReportStatus = async (ownMemberId, internshipProjectId) => {
  try {
    const response = await apiClient.get(
      `/InternshipStatusReport/student-status?memberId=${ownMemberId}&projectId=${internshipProjectId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
        },
      },
    )
    return response.data
  } catch (error) {
    console.error('Error fetching report status:', error)
    throw error
  }
}

export const submitReport = async (payload) => {
  try {
    const response = await apiClient.post('/InternshipStatusReport/add', payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error submitting report:', error)
    throw error
  }
}

export const fetchCategories = async () => {
  try {
    const response = await apiClient.get('/InternLessonLearned/Categories/List')
    return response.data
  } catch (error) {
    console.error('Failed to load categories', error)
    throw error
  }
}

export const fetchViewHistory = async (memberId) => {
  try {
    const response = await apiClient.get(
      `/InternIssueChallenge/Student/List?memberId=${memberId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
        },
      },
    )
    return response.data
  } catch (error) {
    console.error('Failed to fetch view history', error)
    throw error
  }
}

export const submitIssueReport = async (requestData) => {
  try {
    const response = await apiClient.post('/InternIssueChallenge', requestData)
    return response.data
  } catch (error) {
    console.error('Error submitting issue:', error)
    throw error
  }
}

import React, { useEffect, useState } from 'react'
import { Button, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const { Text } = Typography

const useLandingPage = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const [groupData, setGroupData] = useState([])
  const [loading, setLoading] = useState(true)
  const [chartLoading, setChartLoading] = useState(true)
  const [statusData, setStatusData] = useState(null)
  const [completionData, setCompletionData] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    fetchMentorGroups()
    fetchDashboardData()
  }, [])

  const fetchMentorGroups = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/InternshipGroup/Mentor/Groups?mentorId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: '*/*',
          },
        },
      )
      setGroupData(response.data)
    } catch (error) {
      console.error('Error fetching mentor groups:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Mentor/Dashboard?mentorId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: '*/*',
          },
        },
      )
      const { projectStatusChart, completionStatusChart } = response.data
      setStatusData({
        labels: projectStatusChart.map((item) => item.projectName),
        datasets: [
          {
            label: '',
            data: projectStatusChart.map((item) => item.completionPercentage),
            backgroundColor: ['#3F51B5', '#009688', '#9C27B0'],
            barThickness: 20,
          },
        ],
      })

      // Completion Status Chart Data
      setCompletionData({
        labels: completionStatusChart.map((item) => item.status),
        datasets: [
          {
            data: completionStatusChart.map((item) => item.count),
            backgroundColor: ['#4CAF50', '#FF9800', '#F44336'],
          },
        ],
      })
    } catch (error) {
      console.error('Error fetching dashboard data:', error)
    } finally {
      setChartLoading(false)
    }
  }

  const columns = [
    {
      title: 'Group ID',
      dataIndex: 'groupId',
      key: 'groupId',
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Assigned Project',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (text) =>
        text ? text : <Text style={{ color: 'red', fontWeight: 'bold' }}>Not Assigned</Text>,
    },
    {
      title: 'Number of Students',
      dataIndex: 'membersCount',
      key: 'membersCount',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="link" onClick={() => navigate(`/group-detail/${record.groupId}`)}>
          View Detail
        </Button>
      ),
    },
  ]

  return { groupData, loading, columns, chartLoading, statusData, completionData }
}

export default useLandingPage

import { useEffect, useRef } from 'react'
import * as signalR from '@microsoft/signalr'

const useSignalRChat = (
  setChatMessages,
  setConversations,
  selectedChatId,
  setLoader,
  setHubConnection,
) => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const connectionRef = useRef(null) // Ref to store connection

  useEffect(() => {
    if (!userId || !token) return

    if (connectionRef.current) {
      // If connection already exists, do nothing
      return
    }

    const connection = new signalR.HubConnectionBuilder()
      .withUrl('https://soft.recipe4foodies.com/hubs/chat', { accessTokenFactory: () => token })
      .withAutomaticReconnect()
      .build()

    connectionRef.current = connection // Store connection in ref

    const startConnection = async () => {
      try {
        await connection.start()
        // console.log('✅ SignalR Connected')
        setHubConnection(connection)
      } catch (err) {
        console.error('❌ SignalR Connection Error: ', err)
        setTimeout(startConnection, 5000) // Retry after 5 seconds
      }
    }
    startConnection()

    connection.on('GetConversations', (chats) => {
      // console.log('🔄 Get Conversation List:', chats)
      setConversations(chats || [])
      setLoader(false)
    })

    connection.on('ReceiveMessage', (message) => {
      // if (selectedChatId === message.conversationId) {
      setChatMessages((prev) => [
        ...prev,
        {
          text: message.content,
          senderId: message.senderId,
          senderName: message.senderName,
          senderProfile: message.senderProfileImage,
          isSentByUser: message.senderId === userId,
        },
      ])
      // }
    })

    connection.on('UpdateConversationList', (conversation) => {
      // console.log('🔄 Updated Conversation List:', conversation)
      setConversations((prev) => {
        const updatedConversations = Array.isArray(conversation) ? conversation : [conversation]
        const mergedConversations = [...prev]

        updatedConversations.forEach((conv) => {
          const existingIndex = mergedConversations.findIndex(
            (item) => item.conversationId === conv.conversationId,
          )

          if (existingIndex !== -1) {
            mergedConversations[existingIndex] = conv
          } else {
            mergedConversations.push(conv)
          }
        })

        return mergedConversations
      })
    })

    return () => {
      console.log('🛑 Disconnecting SignalR')
      connection.stop()
    }
  }, []) // Empty dependency array to run only once

  return connectionRef.current // Return connection reference if needed
}

export default useSignalRChat

// import { useEffect } from 'react'
// import * as signalR from '@microsoft/signalr'

// const useSignalRChat = (
//   setChatMessages,
//   setConversations,
//   selectedChatId,
//   setLoader,
//   setHubConnection,
// ) => {
//   const userId = localStorage.getItem('userId')
//   const token = localStorage.getItem('token')

//   useEffect(() => {
//     if (!userId || !token) return

//     const connection = new signalR.HubConnectionBuilder()
//       .withUrl('https://soft.recipe4foodies.com/hubs/chat', { accessTokenFactory: () => token })
//       .withAutomaticReconnect()
//       .build()

//     const startConnection = async () => {
//       try {
//         await connection.start()
//         // console.log('✅ SignalR Connected')
//         setHubConnection(connection)
//       } catch (err) {
//         console.error('❌ SignalR Connection Error: ', err)
//         // setTimeout(startConnection, 5000) // Retry connection after 5 seconds
//       }
//     }
//     startConnection()

//     connection.on('GetConversations', (chats) => {
//       // console.log('🔄 Get Conversation List:', chats)
//       setConversations(chats || [])
//       setLoader(false)
//     })

//     connection.on('ReceiveMessage', (message) => {
//       if (selectedChatId === message.conversationId) {
//         setChatMessages((prev) => [
//           ...prev,
//           {
//             text: message.content,
//             senderId: message.senderId,
//             senderName: message.senderName,
//             senderProfile: message.senderProfileImage,
//             isSentByUser: message.senderId === userId,
//           },
//         ])
//       }
//     })

//     connection.on('UpdateConversationList', (conversation) => {
//       // console.log('🔄 Updated Conversation List:', conversation)

//       setConversations((prev) => {
//         const updatedConversations = Array.isArray(conversation) ? conversation : [conversation]
//         const mergedConversations = [...prev]

//         updatedConversations.forEach((conv) => {
//           const existingIndex = mergedConversations.findIndex(
//             (item) => item.conversationId === conv.conversationId,
//           )

//           if (existingIndex !== -1) {
//             mergedConversations[existingIndex] = conv
//           } else {
//             mergedConversations.push(conv)
//           }
//         })

//         return mergedConversations
//       })
//     })

//     return () => connection.stop()
//   }, [userId, token, selectedChatId])
// }

// export default useSignalRChat

import React, { useEffect, useState } from 'react'
import { Card, Typography, Button, message, Empty } from 'antd'
import { useNavigate } from 'react-router-dom'
import { fetchData } from 'src/Services/MentorServices/ProjectsService'
import moment from 'moment'
import DynamicBadge from 'src/Components/Badges/MentorBadges/DynamicBadges'

const { Title, Text } = Typography

const ProjectsPage = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedTab) {
      const fetchProjects = async () => {
        setLoading(true)
        try {
          const completed = selectedTab === 'completed'
          const data = await fetchData(userId, token, completed)
          setProjects(data)
        } catch (error) {
          message.error('Failed to fetch projects')
          console.error(error)
        } finally {
          setLoading(false)
        }
      }
      fetchProjects()
    }
  }, [selectedTab])

  const handleManageClick = (id) => {
    navigate(`/projects-Detail/${id}`)
  }

  return (
    <>
      {!selectedTab && (
        <Card title="Projects" className="card-professional">
          {/* Initially Show Two Circular Boxes */}

          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}
          >
            <div
              onClick={() => setSelectedTab('inflight')}
              className="circular-card newcard-main-3"
            >
              <span>
                <DynamicBadge dataKey="projectInflight" name="" variant="alternate" />
              </span>
              <h3>Project Inflight</h3>
            </div>
            <div
              onClick={() => setSelectedTab('completed')}
              className="circular-card newcard-main-4"
            >
              <span>
                <DynamicBadge dataKey="projectCompleted" name="" variant="alternate" />
              </span>
              <h3>Completed Project</h3>
            </div>
            <div
              onClick={() => navigate('/lesson-learned')}
              className="circular-card newcard-main-6"
            >
              <span>
                <DynamicBadge dataKey="lessonLearned" name="" variant="alternate" />
              </span>
              <h3>Lesson Learned</h3>
            </div>
            <div
              onClick={() => navigate('/progress-report')}
              className="circular-card newcard-main-8"
            >
              <span>
                <DynamicBadge dataKey="progressReport" name="" variant="alternate" />
              </span>
              <h3>Progress Report</h3>
            </div>
            <div
              onClick={() => navigate('/project-certificates')}
              className="circular-card newcard-main-9"
            >
              <span>
                <DynamicBadge dataKey="projectCertificate" name="" variant="alternate" />
              </span>
              <h3>Project Certificate</h3>
            </div>
          </div>
        </Card>
      )}
      {/* Show Projects if Inflight is selected */}
      {selectedTab === 'inflight' && (
        <Card
          loading={loading}
          className="card-professional"
          title="📁 Project Inflight"
          extra={
            <Button type="default" onClick={() => setSelectedTab(null)}>
              Back
            </Button>
          }
        >
          {projects.length === 0 ? (
            <Empty description="No projects found" />
          ) : (
            projects.map((project) => (
              <Card
                key={project.internshipProjectId}
                style={{ marginBottom: 20, background: '#fafafa', borderRadius: 10 }}
              >
                <Title level={4} style={{ color: '#1890ff', marginBottom: 10 }}>
                  {project.title}{' '}
                  {project.alertCount > 0 && (
                    <Typography.Text type="danger">({project.alertCount})</Typography.Text>
                  )}
                </Title>
                <Text
                  style={{ fontSize: '14px', color: '#333', display: 'block', marginBottom: 10 }}
                >
                  {project.description}
                </Text>
                <Text type="secondary" style={{ fontSize: '14px', display: 'block' }}>
                  Start Date: {moment(project.startDate).format('MMMM Do YYYY')}
                </Text>
                <Text
                  type="secondary"
                  style={{ fontSize: '14px', display: 'block', marginBottom: 10 }}
                >
                  End Date: {moment(project.endDate).format('MMMM Do YYYY')}
                </Text>
                <Button
                  type="primary"
                  onClick={() => handleManageClick(project.internshipProjectId)}
                >
                  Manage
                </Button>
              </Card>
            ))
          )}
        </Card>
      )}

      {/* Show "Under Construction" Card if Completed Project is selected */}
      {selectedTab === 'completed' && (
        <Card
          loading={loading}
          className="card-professional"
          title="🏗️ Completed Project"
          extra={
            <Button type="default" onClick={() => setSelectedTab(null)}>
              Back
            </Button>
          }
        >
          {projects.length === 0 ? (
            <Empty description="No projects found" />
          ) : (
            projects.map((project) => (
              <Card
                key={project.internshipProjectId}
                style={{ marginBottom: 20, background: '#fafafa', borderRadius: 10 }}
              >
                <Title level={4} style={{ color: '#1890ff', marginBottom: 10 }}>
                  {project.title}
                </Title>
                <Text
                  style={{ fontSize: '14px', color: '#333', display: 'block', marginBottom: 10 }}
                >
                  {project.description}
                </Text>
                <Text type="secondary" style={{ fontSize: '14px', display: 'block' }}>
                  Start Date: {moment(project.startDate).format('MMMM Do YYYY')}
                </Text>
                <Text
                  type="secondary"
                  style={{ fontSize: '14px', display: 'block', marginBottom: 10 }}
                >
                  End Date: {moment(project.endDate).format('MMMM Do YYYY')}
                </Text>
                <Button
                  type="primary"
                  onClick={() => handleManageClick(project.internshipProjectId)}
                >
                  Manage
                </Button>
              </Card>
            ))
          )}
        </Card>
      )}
    </>
  )
}

export default ProjectsPage

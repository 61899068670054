import { useState, useEffect } from 'react'
import axios from 'axios'

const userId = localStorage.getItem('userId')
const token = localStorage.getItem('token')
const role = localStorage.getItem('role')
const API_URL = `https://soft.recipe4foodies.com/api/Chats/New-Chat/Users?userId=${userId}`
const API_URL_For_Mentor_Group_List = `https://soft.recipe4foodies.com/api/Chats/Mentor/GroupList?mentorId=${userId}`
const API_URL_For_Tutor_List = `https://soft.recipe4foodies.com/api/Chats/NewChat/Tutors/ForCounselor?counselorId=${userId}`
const API_URL_For_Counselor_List = `https://soft.recipe4foodies.com/api/Chats/NewChat/Counselors/ForParent?parentId=${userId}`

const useFetchApiData = () => {
  const [students, setStudents] = useState([])
  const [tutors, setTutors] = useState([])
  const [groups, setGroups] = useState([])
  const [counselors, setCounselor] = useState([])
  const [groupChat, setGroupChat] = useState([])

  useEffect(() => {
    fetchUserData()
  }, [])

  useEffect(() => {
    if (role === 'Counselor') {
      fetchTutorList()
    }
  }, [role])

  useEffect(() => {
    if (role === 'Parent') {
      fetchCounselorList()
    }
  }, [role])

  useEffect(() => {
    if (role === 'Mentor') {
      fetchMentorData()
    }
  }, [role])

  const fetchUserData = async () => {
    try {
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
        },
      })

      const { role, users } = response.data

      if (!users || users.length === 0) {
        console.warn('No users found for this role')
        return
      }

      let singleChatData = []
      let groupChatData = []

      if (role === 'Parent') {
        groupChatData = users.map((user) => ({
          id: user.userId,
          name: user.name,
          counselorName: user.counselorName,
          counselorId: user.counselorId,
        }))
      } else if (role === 'Counselor' || role === 'Mentor') {
        singleChatData = users.map((user) => ({
          id: user.userId,
          name: user.name,
        }))

        groupChatData = users.map((user) => ({
          id: user.userId,
          name: `${user.name} (With Parent)`,
        }))
      } else if (role === 'Student') {
        singleChatData = users.map((user) => ({
          id: user.userId,
          name: user.name,
        }))
      } else if (role === 'Tutor') {
        singleChatData = users.map((user) => ({
          id: user.userId,
          name: user.name,
        }))
      }

      setStudents(singleChatData)
      setGroupChat(groupChatData)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  const fetchTutorList = async () => {
    try {
      const response = await axios.get(API_URL_For_Tutor_List, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
        },
      })

      const { role, users } = response.data

      if (!users || users.length === 0) {
        console.warn('No users found for this role')
        return
      }

      let singleChatData = []

      if (role === 'Counselor') {
        singleChatData = users.map((user) => ({
          id: user.userId,
          name: user.name,
        }))
      }

      setTutors(singleChatData)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  const fetchCounselorList = async () => {
    try {
      const response = await axios.get(API_URL_For_Counselor_List, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
        },
      })

      const { role, users } = response.data

      if (!users || users.length === 0) {
        console.warn('No users found for this role')
        return
      }

      let singleChatData = []

      if (role === 'Parent') {
        singleChatData = users.map((user) => ({
          id: user.userId,
          name: user.name,
        }))
      }

      setCounselor(singleChatData)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  const fetchMentorData = async () => {
    try {
      const response = await axios.get(API_URL_For_Mentor_Group_List, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
        },
      })

      const { role, groups } = response.data

      if (!groups || groups.length === 0) {
        console.warn('No groups found for this mentor')
        return
      }

      let groupChatData = []

      if (role === 'Mentor') {
        groupChatData = groups.map((group) => ({
          groupId: group.groupId,
          groupName: group.groupName,
        }))
      }

      setGroups(groupChatData)
    } catch (error) {
      console.error('Error fetching group data:', error)
    }
  }

  return { students, groupChat, tutors, counselors, groups }
}

export default useFetchApiData

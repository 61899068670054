import React, { useEffect } from 'react'

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'

import SoftBox from 'src/Components/softData/SoftBox'
import SoftTypography from 'src/Components/softData/SoftTypography'

import SidenavCollapse from 'src/Components/navbarData/Sidenav/SidenavCollapse'
import SidenavCard from 'src/Components/navbarData/Sidenav/SidenavCard'

// Custom styles for the Sidenav
import SidenavRoot from 'src/Components/navbarData/Sidenav/SidenavRoot'
import sidenavLogoLabel from 'src/Components/navbarData/Sidenav/styles/sidenav'

import { useSoftUIController, setMiniSidenav } from 'src/context'

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, transparentSidenav } = controller
  const location = useLocation()
  const { pathname } = location
  const collapseName = pathname.split('/').slice(1)[0]

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200)
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav)

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav)
  }, [dispatch, location])

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
    let returnValue

    if (type === 'collapse') {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavCollapse
            color={color}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </NavLink>
      )
    } else if (type === 'title') {
      returnValue = (
        <SoftTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SoftTypography>
      )
    } else if (type === 'divider') {
      returnValue = <Divider key={key} />
    }

    return returnValue
  })

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      {/* Brand Section - Fixed at the Top */}
      <SoftBox
        pt={3}
        pb={1}
        px={4}
        textAlign="center"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          backgroundColor: '#0C313F',
          zIndex: 10,
          height: '80px', // Fix the height of brand section
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: '1px solid rgba(255, 255, 255, 0.2)', // Optional
        }}
      >
        <SoftBox component={NavLink} display="flex" alignItems="center">
          {brand && <SoftBox component="img" src={brand} alt="Logo" width="2rem" />}
          <SoftBox
            width={!brandName && '100%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography style={{ fontSize: 'medium', color: 'white' }} component="h6">
              {brandName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {/* Scrollable List for Sidebar Items */}
      <SoftBox
        sx={{
          marginTop: '80px',
          height: 'calc(100vh - 80px)',
          // height: 'calc(130vh)',
          overflowY: 'auto',
          paddingTop: '10px',
          paddingRight: '5px',
          '&::-webkit-scrollbar': {
            width: '6px', // Scroll bar width
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Scroll color
            borderRadius: '5px', // Round edges
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent', // Transparent background
          },
        }}
      >
        <List>{renderRoutes}</List>
      </SoftBox>

      <SoftBox pt={2} my={2} mx={2} mt="auto">
        <SidenavCard />
      </SoftBox>
    </SidenavRoot>
  )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Sidenav

import { useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'

let presenceConnection = null

const useSignalRPresence = () => {
  const [unreadMessages, setUnreadMessages] = useState(0)

  const startPresenceHub = () => {
    if (!presenceConnection) {
      presenceConnection = new signalR.HubConnectionBuilder()
        .withUrl('https://soft.recipe4foodies.com/hubs/presence', {
          accessTokenFactory: () => localStorage.getItem('token'),
        })
        .withAutomaticReconnect()
        .build()

      presenceConnection
        .start()
        .then(() => console.log('Presence Hub Connected'))
        .catch((err) => console.error('Presence Hub Error:', err))

      presenceConnection.on('GetUnReadMessages', (unread) => {
        setUnreadMessages(() => unread) // Functional update
      })

      presenceConnection.on('UpdateMessageCount', (count) => {
        setUnreadMessages(() => count) // Functional update
      })
    }
  }

  const stopPresenceHub = () => {
    if (presenceConnection) {
      presenceConnection
        .stop()
        .then(() => console.log('Presence Hub Disconnected'))
        .catch((err) => console.error('Error stopping Presence Hub:', err))
      presenceConnection = null
    }
  }

  useEffect(() => {}, [unreadMessages])

  return { unreadMessages, startPresenceHub, stopPresenceHub }
}

export default useSignalRPresence

// import { useEffect, useState } from 'react'
// import * as signalR from '@microsoft/signalr'

// const useSignalRPresence = () => {
//   const [unreadMessages, setUnreadMessages] = useState(0)
//   useEffect(() => {
//     const presenceConnection = new signalR.HubConnectionBuilder()
//       .withUrl('https://soft.recipe4foodies.com/hubs/presence', {
//         accessTokenFactory: () => localStorage.getItem('token'),
//       })
//       .withAutomaticReconnect()
//       .build()

//     presenceConnection.start().catch((err) => console.error('Presence Hub Error:', err))

//     presenceConnection.on('GetUnReadMessages', (unread) => setUnreadMessages(unread))
//     presenceConnection.on('UpdateMessageCount', (count) => setUnreadMessages(count))

//     return () => presenceConnection.stop()
//   }, [])
//   return { unreadMessages }
// }

// export default useSignalRPresence

import PropTypes from 'prop-types'
import React, { createContext, useContext } from 'react'
import useSignalRPresence from 'src/Hooks/CounselorHooks/SingleR/useSignalRPresence'

const SignalRContext = createContext()

export const SignalRProvider = ({ children, unreadMessages }) => {
  const { startPresenceHub } = useSignalRPresence()

  return (
    <SignalRContext.Provider value={{ unreadMessages, startPresenceHub }}>
      {children}
    </SignalRContext.Provider>
  )
}

SignalRProvider.propTypes = {
  children: PropTypes.node.isRequired,
  unreadMessages: PropTypes.number.isRequired,
}

export const useSignalR = () => useContext(SignalRContext)

// src/CounselorContext/BadgeContext.js

import React, { createContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

// Create the context
export const BadgeContext = createContext()

export const BadgeProvider = ({ children }) => {
  const [badgeData, setBadgeData] = useState({})
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')

  // Function to fetch badge data
  const fetchBadgeData = useCallback(async () => {
    if (!userId || !token) {
      console.error('Counselor ID or Token is missing')
      return
    }

    try {
      const response = await fetch(
        `https://soft.recipe4foodies.com/api/Mentor/Aside-Bar?mentorId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const data = await response.json()
      setBadgeData(data)
    } catch (error) {
      console.error('Failed to fetch badge data:', error)
    }
  }, [])

  return (
    <BadgeContext.Provider value={{ badgeData, fetchBadgeData }}>{children}</BadgeContext.Provider>
  )
}

BadgeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

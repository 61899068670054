import React, { useEffect, useState } from 'react'
import { Card, Button, Typography, Empty } from 'antd'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { fetchReports } from 'src/Services/MentorServices/ProjectsService'

const { Title, Text } = Typography

const StatusReport = () => {
  const { memberId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')

  // Get studentName from location state
  const { studentName } = location.state || { studentName: '' }

  const fetchStatusReport = async (memberId) => {
    try {
      const data = await fetchReports(memberId, token)
      setReports(data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (memberId) {
      fetchStatusReport(memberId)
    }
  }, [memberId])

  return (
    <>
      <Card
        loading={loading}
        className="card-professional"
        title={`Progress Report - ${studentName}`}
        extra={<Button onClick={() => navigate(-1)}>Back</Button>}
      >
        {reports.length === 0 ? (
          <div style={{ textAlign: 'center', padding: '50px 0' }}>
            <Empty description="No status reports have been submitted yet" />
          </div>
        ) : (
          <div
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
              paddingRight: '10px',
            }}
          >
            {reports.map((report) => (
              <Card
                key={report.reportId}
                style={{
                  marginBottom: 16,
                  borderRadius: 10,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: 20,
                }}
              >
                <Title level={4} style={{ color: '#1890ff', marginBottom: 10 }}>
                  Week {report.weekNumber}
                </Title>
                <Text strong>Description:</Text>
                <p style={{ marginBottom: 10 }}>{report.reportText}</p>
                <Text type="secondary">
                  Submitted on: {new Date(report.submissionDate).toLocaleDateString()}
                </Text>
              </Card>
            ))}
          </div>
        )}
      </Card>
    </>
  )
}

export default StatusReport

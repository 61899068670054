import { useEffect, useState } from 'react'
import { message } from 'antd'
import { fetchLessonLearnedData } from 'src/Services/MentorServices/LessonLearnedService'

const useLessonLearned = (userId, token) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await fetchLessonLearnedData(userId, token)
        const formattedData = response.map((item, index) => ({
          key: index.toString(),
          phaseId: item.phaseId,
          lessonLearnedId: item.lessonLearnedId,
          projectId: item.projectId,
          projectName: item.projectName,
          groupName: item.groupName,
          completedPhase: item.phaseName,
        }))
        setData(formattedData)
      } catch (error) {
        message.error('Failed to fetch data')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [userId, token])

  return { data, loading }
}

export default useLessonLearned

import apiClient from 'src/Common/apiClient'

const token = localStorage.getItem('token')
// Mentor details fetch karne ka function
export const fetchMentorDetail = async (id) => {
  try {
    const response = await apiClient.get(`/IdeaFactoryProgram/Mentor/Detail?programId=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching details:', error)
    throw error
  }
}

// Resubmission toggle API call
export const toggleResubmission = async (templateId) => {
  try {
    await apiClient.post(
      `/IdeaFactoryProgram/Toggle-Resubmission?templateId=${templateId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } catch (error) {
    console.error('Error toggling resubmission:', error)
    throw error
  }
}

export const fileSharing = async (id) => {
  try {
    const response = await apiClient.get(
      `/IdeaFactoryProgram/Mentor/Share-ProjectPlan?programId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response.data
  } catch (error) {
    console.error('Error fetching details:', error)
    throw error
  }
}

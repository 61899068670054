import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { BadgeContext } from 'src/context/MentorContext/BadgeContext'

const DynamicBadge = ({ dataKey, name, essayReviewApplicationId, variant }) => {
  const { badgeData, fetchBadgeData } = useContext(BadgeContext)
  const location = useLocation()

  useEffect(() => {
    fetchBadgeData()
  }, [dataKey, location, essayReviewApplicationId, fetchBadgeData])

  useEffect(() => {
    const handleBadgeUpdate = () => fetchBadgeData()
    window.updateBadgeCount = handleBadgeUpdate
    return () => {
      window.updateBadgeCount = null
    }
  }, [essayReviewApplicationId])

  const badgeCount = badgeData?.[dataKey]?.count || 0

  // CSS class dynamically select karega
  const containerClass = variant === 'alternate' ? 'alternate-badge' : 'default-badge'
  const badgeClass = variant === 'alternate' ? 'alternate-badge-style' : 'default-badge-style'

  return (
    <div className={containerClass}>
      <span>{name}</span>
      {badgeCount > 0 && <Badge badgeContent={badgeCount} color="error" className={badgeClass} />}
    </div>
  )
}

DynamicBadge.propTypes = {
  dataKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  essayReviewApplicationId: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'alternate']),
}

export default DynamicBadge

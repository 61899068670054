import React, { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card, Button, Table, Empty } from 'antd' // Import Spin component for loading
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import axios from 'axios'
import PropTypes from 'prop-types'

const FinalStudentReport = ({ careerPathReportId, studentId, Back }) => {
  const [studentData, setStudentData] = useState(null)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const role = localStorage.getItem('role')
  const roles = JSON.parse(localStorage.getItem('roles') || '[]')

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/CareerPath/Final-Report?studentId=${studentId}&careerPathReportId=${careerPathReportId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        setStudentData(response.data)
        if (typeof window.updateBadgeCount === 'function') {
          window.updateBadgeCount()
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setDataLoading(false)
      }
    }

    fetchStudentData()
  }, [token])

  const handleDownloadPDF = () => {
    setLoading(true)
    const content = document.getElementById('student-report')
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF('p', 'mm', 'a4')
      const imgWidth = 190 // Adjust based on your content width
      const pageHeight = 297 // A4 page height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      let heightLeft = imgHeight
      let position = 10

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight)
      heightLeft -= pageHeight

      while (heightLeft > 0) {
        position = heightLeft - imgHeight
        pdf.addPage()
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
      }

      pdf.save('FinalStudentReport.pdf')
      setLoading(false)
    })
  }

  // Prevent rendering charts if data is not loaded
  if (dataLoading) {
    return <Card loading={true} title="Final Student Report"></Card>
  } else if (!studentData) {
    return (
      <Card title="Final Student Report">
        <Empty description="No Data Available" />
      </Card>
    )
  }

  // Behavior, personality, and scatter options setup
  const behaviorOptions = {
    series: [
      {
        name: 'Scores',
        data:
          studentData?.behaviorReport?.behaviorGoals?.map((goal) => goal.counselorAssessment) || [],
      },
    ],
    chart: { type: 'bar', height: 350 },
    xaxis: {
      categories:
        studentData?.behaviorReport?.behaviorGoals?.map((goal) => goal.goalDescription) || [],
    },
  }

  const personalityOptions = {
    series: [{ name: 'Traits', data: studentData?.personalityTrait?.data || [] }],
    chart: { type: 'radar', height: 750 },
    labels: studentData?.personalityTrait?.labels || [],
  }

  const scatterData = studentData?.careerPath?.scatterData || []

  const scatterOptions = {
    series: [{ name: 'Attribute Scores', data: scatterData }],
    chart: { type: 'scatter', height: 350 },
    xaxis: { type: 'category', title: { text: 'Derived Attributes' } },
    yaxis: { title: { text: 'Average Scores' }, min: 1, max: 4, tickAmount: 3 },
    tooltip: {
      shared: false,
      intersect: true,
      x: { formatter: (val) => `Attribute: ${val}` },
      y: { formatter: (val) => `Score: ${val}` },
    },
  }

  const columns = [
    {
      title: 'University Id',
      dataIndex: 'universityId',
      key: 'universityId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Probability With SAT',
      dataIndex: 'probabilityWithSAT',
      key: 'probabilityWithSAT',
    },
    {
      title: 'Probability Without SAT',
      dataIndex: 'probabilityNoSAT',
      key: 'probabilityNoSAT',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
    },
  ]

  const majorColumns = [
    {
      title: 'Major Id',
      dataIndex: 'majorId',
      key: 'majorId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ]

  const legendStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flexWrap: 'wrap',
    marginBottom: '20px',
  }

  return (
    <Card
      title="Final Student Report"
      extra={
        <>
          {!((role === 'Parent' && roles.includes('Student')) || role === 'Student') && (
            <Button type="primary" onClick={handleDownloadPDF} disabled={loading}>
              {loading ? 'Please wait...' : 'Download PDF'}
            </Button>
          )}
          &nbsp;&nbsp;&nbsp;
          <Button type="default" onClick={Back}>
            Back
          </Button>
        </>
      }
    >
      <div id="student-report">
        {/* Student Information Section */}
        <div className="section">
          <h5>Student Information</h5>
          <br />
          <ul>
            <li>
              <strong>Student ID:</strong> {studentData.studentId}
            </li>
            <li>
              <strong>Student Name:</strong> {studentData.studentName}
            </li>
            <li>
              <strong>Counselor Name:</strong> {studentData.counselorName}
            </li>
            {/* <li>
              <strong>Total Sessions:</strong> {studentData.totalSessions}
            </li> */}
          </ul>
        </div>
        <br />
        {/* Behavior Agreement Assessment Section */}
        <div className="section">
          <h5>Behavior Agreement Assessment</h5>
          <div className="chart-container">
            <ApexCharts
              options={behaviorOptions}
              series={behaviorOptions.series}
              type="bar"
              height={behaviorOptions.chart.height}
            />
          </div>
          <p>
            <strong>Behavior Agreement Sessions Completed:</strong>{' '}
            {studentData.behaviorReport ? studentData.behaviorReport.sessionsCompleted : 'N/A'}
          </p>
          <p>
            <strong>Overall Performance Score:</strong>{' '}
            {studentData.behaviorReport
              ? studentData.behaviorReport.overallPerformanceScore
              : 'N/A'}
          </p>
          <p>
            <strong>Key Strength:</strong>{' '}
            {studentData.behaviorReport ? studentData.behaviorReport.keyStrength : 'N/A'}
          </p>
          <p>
            <strong>Areas for Improvement:</strong>{' '}
            {studentData.behaviorReport ? studentData.behaviorReport.areasForImprovement : 'N/A'}
          </p>
        </div>
        <hr />
        {/* Personality Traits Section */}
        <div className="section">
          <h5>Personality Traits</h5>
          {!((role === 'Parent' && roles.includes('Student')) || role === 'Student') && (
            <ul>
              {studentData.personalityTrait && studentData.personalityTrait.lagends ? (
                <div style={legendStyle}>
                  <div style={{ flexBasis: '100%' }}>
                    <strong style={{ fontSize: '18px' }}>Legends:</strong>
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: '16px',
                    }}
                  >
                    <div>
                      <strong>Type A or 1:</strong> Competitive, Time Urgent, Hostile and Aggressive
                    </div>
                    <div>
                      <strong>Type B or 2:</strong> Relaxed, Patient, Easy Going, laid back approach
                      to life
                    </div>
                    <div>
                      <strong>Type C or 3:</strong> Detailed Oriented, Precise, and Logical
                    </div>
                    <div>
                      <strong>Type D or 4:</strong> Pessimistic, and Anxious
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <li>
                    <strong>N/A</strong>
                  </li>
                </>
              )}
            </ul>
          )}
          <div className="chart-container">
            <ApexCharts
              options={personalityOptions}
              series={personalityOptions.series}
              type="radar"
              height={personalityOptions.chart.height}
            />
          </div>
          <p>
            <strong>Student Type:</strong>{' '}
            {studentData.personalityTrait ? studentData.personalityTrait.studentType : 'N/A'}
          </p>
          <p>
            <strong>Description:</strong>{' '}
            {studentData.personalityTrait ? studentData.personalityTrait.description : 'N/A'}
          </p>
        </div>
        <br />
        <br />
        <hr />
        {/* Scatterplot Analysis */}
        <div className="section">
          <h5>Career Path Analysis Based on Counselor Assessment</h5>
          <p>{studentData.careerPath.description}</p>
          <div className="chart-container">
            <ApexCharts
              options={scatterOptions}
              series={scatterOptions.series}
              type="scatter"
              height={scatterOptions.chart.height}
            />
          </div>
        </div>

        {/* University Recommendation and Major Path */}
        {studentData.universityRecommendation ? (
          <>
            {/* University Recommendation Path */}
            <div className="section">
              <h5>University Recommendations</h5>
              <Table
                dataSource={studentData.universityRecommendation.recommendedUniversities}
                columns={columns}
                pagination={{ pageSize: 30 }}
              />
            </div>

            {/* University Major Path */}
            <div className="section">
              <h5>University Majors</h5>
              <Table
                dataSource={studentData.universityRecommendation.recommendedMajors}
                columns={majorColumns}
                pagination={{ pageSize: 30 }}
              />
            </div>
          </>
        ) : null}

        {/* Career Path Suggestions */}
        <div className="section">
          <h5>Career Path Recommendations</h5>
          <ul>
            {studentData.careerPath.careerSuggestions.map((suggestion, index) => (
              <li key={index}>
                <strong>{suggestion.pathName}</strong>{' '}
                {/* <strong>{suggestion.pathName}:</strong>{' '} */}
                {/* {suggestion.description ? suggestion.description : 'N/A'} */}
              </li>
            ))}
          </ul>
        </div>

        {/* Counselor Notes */}
        <div className="section">
          <h5>Counselor`s Notes</h5>
          <ul>
            {studentData.notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
        </div>
      </div>
    </Card>
  )
}

FinalStudentReport.propTypes = {
  studentId: PropTypes.number.isRequired,
  careerPathReportId: PropTypes.number.isRequired,
  Back: PropTypes.func.isRequired,
}

export default FinalStudentReport

import axios from 'axios'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchGroupDetails } from 'src/Services/MentorServices/LandingPageService'

const useLandingPageHooks = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const { groupId } = useParams()

  const [groupName, setGroupName] = useState('')
  const [projectAssigned, setProjectAssigned] = useState('')
  const [tasks, setTasks] = useState([])
  const [weeklyPlans, setWeeklyPlans] = useState([])
  const [members, setMembers] = useState([])
  const [groupDetail, setGroupDetail] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchDetails()
    fetchGroupDetail()
  }, [groupId, userId, token])

  const fetchDetails = async () => {
    if (groupId) {
      try {
        const selectedGroup = await fetchGroupDetails(userId, token, groupId)
        if (selectedGroup) {
          setGroupName(selectedGroup.groupName)
          setMembers(selectedGroup.members)
        }
      } catch (error) {
        console.error('Error fetching group details:', error)
      }
    }
  }

  const fetchGroupDetail = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/InternshipGroup/Counselor/Groups/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setGroupDetail(response.data)
      setProjectAssigned(response.data.projectAssigned)
    } catch (error) {
      console.error('Error fetching group details:', error)
    } finally {
      setLoading(false)
    }
  }

  const addTask = () => {
    setTasks([
      ...tasks,
      { id: Date.now(), title: '', deadline: '', memberId: null, description: '' },
    ])
  }

  const removeTask = (id) => {
    setTasks(tasks.filter((task) => task.id !== id))
  }

  const addWeeklyPlan = () => {
    setWeeklyPlans([
      ...weeklyPlans,
      { id: Date.now(), weekNumber: '', goals: '', tasksSummary: '' },
    ])
  }

  const removeWeeklyPlan = (id) => {
    setWeeklyPlans(weeklyPlans.filter((plan) => plan.id !== id))
  }

  return {
    groupName,
    tasks,
    weeklyPlans,
    members,
    addTask,
    removeTask,
    addWeeklyPlan,
    removeWeeklyPlan,
    setTasks,
    setWeeklyPlans,
    groupDetail,
    loading,
    projectAssigned,
  }
}

export default useLandingPageHooks

import { message } from 'antd'
import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://soft.recipe4foodies.com/api',
})

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    const currentUrl = window.location.pathname

    // Stop loader if the URL is one of the specified login URLs
    if (['/login', '/counselorLogin', '/tutorLogin'].includes(currentUrl)) {
      if (config.setLoading) {
        config.setLoading(false)
      }
      return config
    }

    if (!token) {
      // window.location.href = '/login'
      return
    }

    // Start the loader when a request is made
    if (config && config.setLoading) {
      config.setLoading(true)
    }
    return config
  },
  (error) => {
    // Stop the loader if there's an error during the request
    if (error.config && error.config.setLoading) {
      error.config.setLoading(false)
    }
    return Promise.reject(error)
  },
)

export const setupInterceptors = (navigate, setLoading) => {
  apiClient.interceptors.response.use(
    (response) => {
      // Stop the loader when the response is successful
      if (response.config && response.config.setLoading) {
        response.config.setLoading(false)
      }
      return response
    },
    (error) => {
      // Stop the loader when there's an error in the response
      if (error.config && error.config.setLoading) {
        error.config.setLoading(false)
      }
      if (error.handled) {
        return Promise.reject(error)
      }
      if (!error.response) {
        message.error('Network error! Please try again later.')
      } else if (error.response.status === 400) {
        message.error(error.response.data)
      } else if (error.response.status === 401) {
        message.error(error.response.data || 'Session expired. Redirecting to login...')
        localStorage.clear()
        setTimeout(() => navigate('/login'), 100)
      } else if (error.response.status === 403) {
        message.error('Access Denied')
      } else if (error.response.status === 404) {
        message.error(error.response.data || 'Resource Not found')
      } else if (error.response.status === 500) {
        message.error(error.response.data || 'Something went wrong! Please try again later')
      } else {
        message.error('Something went wrong')
      }
      error.handled = true

      return Promise.reject(error)
    },
  )
}

export default apiClient

// import { message } from 'antd'
// import axios from 'axios'

// const apiClient = axios.create({
//   baseURL: 'https://soft.recipe4foodies.com/api',
// })

// apiClient.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token')
//     if (!token) {
//       window.location.href = '/login'
//       return Promise.reject(new Error('Unauthorized: No token in local storage'))
//     }
//     return config
//   },
//   (error) => Promise.reject(error),
// )

// export const setupInterceptors = (navigate, setLoading) => {
//   apiClient.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       if (!error.response) {
//         navigate('/login')
//         localStorage.clear()
//         return Promise.reject(error)
//       }
//       if (error.handled) {
//         return Promise.reject(error)
//       }
//       if (error.response.status === 400) {
//         message.error(error.response.data)
//       } else if (error.response.status === 401) {
//         message.error(error.response.data)
//         localStorage.clear()
//         setTimeout(() => navigate('/login'), 100)
//       } else if (error.response.status === 403) {
//         message.error('Access Denied')
//       } else if (error.response.status === 404) {
//         message.error(error.response.data || 'Resource Not found')
//       } else {
//         message.error('Something went wrong')
//       }
//       error.handled = true

//       return Promise.reject(error)
//     },
//   )
// }

// export default apiClient

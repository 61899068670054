import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import DashboardLayout from 'src/Components/navbarData/LayoutContainers/DashboardLayout/index'
import DashboardNavbar from 'src/Components/navbarData/Navbars/MentorNavbar/index'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Sidenav from 'src/Components/navbarData/Sidenav'
import theme from 'src/assets/theme'
import mentorRoutes from 'src/Routes/MentorRoutes'
import { useSoftUIController } from 'src/context'
// import brand from 'src/assets/images/logo-ct.png'
import brand from 'src/assets/images/journey.png'
import MultiLogin from 'src/AdminLayouts/MultiLogin'
import InactivityTimer from 'src/Common/ActivityTimer'
import { NavbarVisibilityProvider } from 'src/Common/NavbarVisibilty'
import ChangePassword from 'src/Pages/StudentPages/authentication/changepassword'
import ProfileForm from '../MentorProfile/MentorProfile'
import MentorGroupDetail from '../dashboard/components/LandingPage/MentorGroupDetail'
import AssignInternshipProject from '../dashboard/components/LandingPage/AssignedProject'
import ProjectsDetail from '../Projects/ViewProjectDetail'
import StatusReport from '../Projects/StatusReport'
import IssuesAndChallenges from '../Projects/Issues&Challenges'
import CompletedPhase from '../Projects/LessonLearned/CompletedPhase'
import ProgressReportDetail from '../Projects/ProgressReport/ProgressReportDetail'
import LessonLearned from '../Projects/LessonLearned/LessonLearned'
import ProgressReport from '../Projects/ProgressReport/ProgressReport'
import MentorDashboard from '../dashboard/components/LandingPage/landingPage'
import MentorDetail from '../IdeaFactory/mentorDetail'
import AppMentorProviders from 'src/context/MentorProvider'
const Login = React.lazy(() => import('src/Pages/StudentPages/login/Login'))

function AppMentorContent() {
  const [controller] = useSoftUIController()
  const { layout, sidenavColor } = controller
  const location = useLocation()

  useEffect(() => {
    sessionStorage.setItem('currentPath', location.pathname)
  }, [location.pathname])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />
      }

      return null
    })

  const showSidebar = ![
    '/studentregister',
    '/login',
    '/multiLogin',
    '/forgotpassword',
    '/setPassword',
    '/change-password',
  ].some((pattern) => {
    if (typeof pattern === 'string') {
      // eslint-disable-next-line no-restricted-globals
      return location.pathname === pattern
    } else if (pattern instanceof RegExp) {
      // eslint-disable-next-line no-restricted-globals
      return pattern.test(location.pathname)
    }
  })

  const shouldHideNavbar =
    location.pathname === '/change-password' || location.pathname === '/setPassword'

  return (
    <>
      <AppMentorProviders>
        <ThemeProvider theme={theme}>
          <InactivityTimer />
          <CssBaseline />
          {layout === 'dashboard' && showSidebar && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="Mentor Dashboard"
                routes={mentorRoutes}
              />
            </>
          )}
          <NavbarVisibilityProvider>
            {!shouldHideNavbar ? (
              <DashboardLayout>
                <DashboardNavbar />
                <Routes>
                  {getRoutes(mentorRoutes)}
                  <Route path="*" element={<Navigate to="/mentor-Dashboard" />} />
                  <Route path="/login" name="Login" element={<Login />} />
                  <Route path="/multiLogin" element={<MultiLogin />} />
                  <Route path="/mentor-profile" name="Update Profile" element={<ProfileForm />} />
                  <Route
                    path="/group-detail/:groupId"
                    name="Group Detail"
                    element={<MentorGroupDetail />}
                  />
                  <Route
                    path="/assign-internship-project/:groupId"
                    element={<AssignInternshipProject />}
                  />
                  <Route path="/projects-Detail/:id" element={<ProjectsDetail />} />
                  <Route path="/status-report/:memberId" element={<StatusReport />} />
                  <Route path="/issues-and-report/:memberId" element={<IssuesAndChallenges />} />
                  <Route path="/lesson-learned" element={<LessonLearned />} />
                  <Route path="/progress-report" element={<ProgressReport />} />
                  <Route path="/project-certificates" element={<MentorDashboard />} />
                  <Route path="/mentor-detail/:id" element={<MentorDetail />} />
                  <Route
                    path="/progress-report-detail/:reportId"
                    element={<ProgressReportDetail />}
                  />
                  <Route
                    path="/completed-phase/:lessonLearnedId/:phaseId"
                    element={<CompletedPhase />}
                  />
                </Routes>
              </DashboardLayout>
            ) : (
              <Routes>
                <Route path="/change-password" element={<ChangePassword />} />
              </Routes>
            )}
          </NavbarVisibilityProvider>
        </ThemeProvider>
      </AppMentorProviders>
    </>
  )
}
export default AppMentorContent

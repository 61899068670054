import apiClient from 'src/Common/apiClient'

const fetchGroupDetails = async (userId, token, groupId) => {
  try {
    const response = await apiClient.get(
      `/InternshipGroup/Mentor/Groups/Light?mentorId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response.data.find(
      (group) => group.groupId === parseInt(groupId) && !group.groupAssigned,
    )
  } catch (error) {
    console.error('Error fetching group details:', error)
    throw error
  }
}

export { fetchGroupDetails }

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const InactivityTimer = () => {
  const navigate = useNavigate()

  useEffect(() => {
    let timer
    // const timeoutDuration = 5 * 1000 // 5 sec
    const timeoutDuration = 50 * 60 * 1000 // 50 minutes

    const resetTimer = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        const role = localStorage.getItem('role')
        localStorage.clear()

        // Navigate based on the role
        if (role === 'Counselor') {
          navigate('/counselorLogin')
        } else if (role === 'Tutor') {
          navigate('/tutorLogin')
        } else {
          navigate('/login')
        }
      }, timeoutDuration)
    }

    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('keypress', resetTimer)
    resetTimer()

    return () => {
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('keypress', resetTimer)
      clearTimeout(timer)
    }
  }, [navigate])

  return null
}

export default InactivityTimer

// import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'

// const InactivityTimer = () => {
//   const navigate = useNavigate()

//   useEffect(() => {
//     let timer
//     // const timeoutDuration = 5 * 1000
//     const timeoutDuration = 5 * 60 * 1000

//     const resetTimer = () => {
//       clearTimeout(timer)
//       timer = setTimeout(() => {
//         localStorage.clear()
//         navigate('/login')
//       }, timeoutDuration)
//     }
//     window.addEventListener('mousemove', resetTimer)
//     window.addEventListener('keypress', resetTimer)
//     resetTimer()
//     return () => {
//       window.removeEventListener('mousemove', resetTimer)
//       window.removeEventListener('keypress', resetTimer)
//       clearTimeout(timer)
//     }
//   }, [navigate])

//   return null
// }

// export default InactivityTimer

import React from 'react'

import Shop from '../Components/navbarData/Icons/Shop'
import MentorDashboard from 'src/Pages/Mentor/dashboard/components/LandingPage/landingPage'
import { ChatOutlined, Factory, LibraryAddCheckOutlined, People } from '@mui/icons-material'
import CounselorChatBadge from 'src/Pages/StudentPages/StdRouteBadges/CounselorChatBadge'
import StartChat from 'src/Pages/CounselorPages/dashboard/components/Chat/CounselorChat'
import AssignedStudents from 'src/Pages/Mentor/AssignedStudent'
import ProjectsPage from 'src/Pages/Mentor/Projects/Projects'
import ProjectLibrary from 'src/Pages/Mentor/ProjectLibrary/ProjectLibrary'
import IdeaFactory from '../Pages/Mentor/IdeaFactory/ideafactory'
import DynamicBadge from 'src/Components/Badges/MentorBadges/DynamicBadges'

const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'mentor-Dashboard',
    route: '/mentor-Dashboard',
    icon: <Shop size="12px" />,
    component: <MentorDashboard />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    // name: 'Counselor Chat',
    name: <CounselorChatBadge dataKey="message" name="Messaging" />,
    key: 'messaging',
    route: '/messaging',
    icon: <ChatOutlined size="12px" />,
    component: <StartChat />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: <DynamicBadge dataKey="assignedStudents" name="Assigned Students" />,
    key: 'assigned-students',
    route: '/assigned-students',
    icon: <People size="12px" />,
    component: <AssignedStudents />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: <DynamicBadge dataKey="projectCombined" name="Projects" />,
    key: 'projects',
    route: '/projects',
    icon: <LibraryAddCheckOutlined size="12px" />,
    component: <ProjectsPage />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: <DynamicBadge dataKey="projectLibrary" name="Project Library" />,
    key: 'project-library',
    route: '/project-library',
    icon: <LibraryAddCheckOutlined size="12px" />,
    component: <ProjectLibrary />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: <DynamicBadge dataKey="ideaFactory" name="Idea Factory" />,
    key: 'idea-factory',
    route: '/idea-factory',
    icon: <Factory size="12px" />,
    component: <IdeaFactory />,
    noCollapse: true,
  },
]

export default routes

import apiClient from 'src/Common/apiClient'

export const login = async (email, password) => {
  try {
    const response = await apiClient.post(
      `/Account/Login`,
      {
        email,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

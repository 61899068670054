import React from 'react'
import Card from '@mui/material/Card'
import { card } from 'src/Components/navbarData/Sidenav/styles/sidenavCard'

import { useSoftUIController } from 'src/context'

function SidenavCard() {
  const [controller] = useSoftUIController()
  const { miniSidenav } = controller

  return <Card className="card-professional" sx={(theme) => card(theme, { miniSidenav })}></Card>
}

export default SidenavCard

import apiClient from 'src/Common/apiClient'

// Fetch the profile data
export const fetchProfileData = async (userId, token) => {
  try {
    const response = await apiClient.get(`/Mentor/Profile?mentorId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

// Update the profile data
export const updateProfileData = async (formData, token) => {
  try {
    const response = await apiClient.post('/Mentor/Profile', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error) {
    throw error
  }
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Empty, Input, List } from 'antd'
import { TeamOutlined, UserOutlined } from '@ant-design/icons'

const Sidebar = ({
  activeChats,
  search,
  setSearch,
  setIsModalVisible,
  setIsGroupModalVisible,
  setIsTutorModalVisible,
  setSelectedChatId,
  selectedChatId,
  hoveredId,
  setHoveredId,
  loader,
  conversations,
  unreadMessages,
  setMentorModelVisible,
}) => {
  const role = localStorage.getItem('role')
  const [activeButton, setActiveButton] = useState(null)

  // Filter out conversations without messages
  const filteredConversations = conversations.filter(
    (conversation) => conversation.chatSubTitle && conversation.chatSubTitle.trim() !== '',
  )

  const mappedConversations = filteredConversations.map((conversation) => ({
    id: conversation.conversationId,
    name: conversation.chatTitle,
    type: conversation.chatType === 2 ? 'group' : 'single',
    lastMessage: conversation.chatSubTitle,
    lastActivity: conversation.lastActivity,
    unreadCount: conversation.unReadCount,
    chatMessages: [],
  }))

  const uniqueChats = new Map()

  ;[...activeChats, ...mappedConversations].forEach((chat) => {
    uniqueChats.set(chat.id, chat)
  })

  const allChats = Array.from(uniqueChats.values())
  const getButtonStyle = (buttonName) => ({
    backgroundColor: activeButton === buttonName ? '#075e54' : '#d9d9d9',
    color: activeButton === buttonName ? 'white' : 'black',
    borderColor: 'transparent',
    height: '30px',
  })

  return (
    <div className="sidebar" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Buttons Section */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginBottom: '10px',
          justifyContent: 'center',
        }}
      >
        <Button
          title={
            role === 'Tutor' || role === 'Parent' || role === 'Student'
              ? 'New Chat with Counselor'
              : 'New Chat'
          }
          type="default"
          icon={<UserOutlined />}
          onClick={() => {
            setIsModalVisible(true)
            setActiveButton('newChat')
          }}
          className="chat-button"
          style={getButtonStyle('newChat')}
        >
          {role === 'Tutor' || role === 'Parent' || role === 'Student'
            ? 'New Chat with Counselor'
            : 'Chat with Students'}
        </Button>

        {!(role === 'Student' || role === 'Parent' || role === 'Tutor' || role === 'Mentor') && (
          <Button
            type="default"
            icon={<UserOutlined />}
            block
            onClick={() => {
              setIsTutorModalVisible(true)
              setActiveButton('tutorChat')
            }}
            className="chat-button"
            style={getButtonStyle('tutorChat')}
          >
            Chat with Tutor
          </Button>
        )}

        {!(role === 'Student' || role === 'Tutor' || role === 'Mentor') && (
          <Button
            type="default"
            icon={<TeamOutlined />}
            block
            onClick={() => {
              setIsGroupModalVisible(true)
              setActiveButton('groupChat')
            }}
            className="chat-button"
            style={getButtonStyle('groupChat')}
          >
            Group Chat
          </Button>
        )}

        {role === 'Mentor' && (
          <Button
            type="default"
            icon={<TeamOutlined />}
            block
            onClick={() => {
              setMentorModelVisible(true)
              setActiveButton('groupChat')
            }}
            className="chat-button"
            style={getButtonStyle('groupChat')}
          >
            Create Group Chat with Students
          </Button>
        )}
      </div>

      {/* Search Input */}
      <Input
        placeholder="Search or start a new chat..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: '10px' }}
      />

      <div style={{ flex: 1, overflowY: 'auto', paddingRight: '8px' }}>
        {loader ? (
          'Chats Loading...'
        ) : allChats.length > 0 ? (
          <List
            bordered
            dataSource={allChats.filter((chat) =>
              chat.name?.toLowerCase().includes(search.toLowerCase()),
            )}
            renderItem={(chat) => {
              const lastActivityDate = new Date(chat.lastActivity)
              const today = new Date()
              const yesterday = new Date()
              yesterday.setDate(today.getDate() - 1)

              const isToday = lastActivityDate.toDateString() === today.toDateString()
              const isYesterday = lastActivityDate.toDateString() === yesterday.toDateString()

              const formattedTime = lastActivityDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })

              const formattedDate = lastActivityDate.toLocaleDateString()

              return (
                <List.Item
                  key={chat.id}
                  onMouseEnter={() => setHoveredId(chat.id)}
                  onMouseLeave={() => setHoveredId(null)}
                  onClick={() => {
                    setSelectedChatId(chat.id)
                  }}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    backgroundColor:
                      selectedChatId === chat.id
                        ? '#d6d6d6'
                        : hoveredId === chat.id
                        ? '#f0f0f0'
                        : 'transparent',
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  {/* Left Section: Chat Name + Last Message */}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {chat.type === 'group' ? (
                      <TeamOutlined style={{ color: '#1890ff', fontSize: '16px' }} />
                    ) : (
                      <UserOutlined style={{ color: '#52c41a', fontSize: '16px' }} />
                    )}
                    <div>
                      <span style={{ fontWeight: 'bold' }}>{chat.name}</span>
                      <div
                        style={{
                          fontSize: '12px',
                          color: '#666',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {chat.lastMessage?.length > 40
                          ? chat.lastMessage.substring(0, 40) + '...'
                          : chat.lastMessage}
                      </div>
                    </div>
                  </div>

                  {/* Right Section: Last Activity & Unread Count */}
                  <div style={{ textAlign: 'right' }}>
                    <strong style={{ fontSize: '12px', color: '#248E47' }}>
                      {formattedDate && !isNaN(new Date(formattedDate).getTime())
                        ? isToday
                          ? formattedTime
                          : isYesterday
                          ? 'Yesterday'
                          : formattedDate
                        : ''}
                    </strong>

                    {chat.unreadCount > 0 && (
                      <div
                        style={{
                          backgroundColor: '#25D366',
                          color: 'white',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          marginTop: '5px',
                          marginLeft: '40px',
                        }}
                      >
                        {chat.unreadCount || unreadMessages}
                        {/* {chat.unreadCount} */}
                      </div>
                    )}
                  </div>
                </List.Item>
              )
            }}
          />
        ) : (
          <Empty description="No Chats Available" />
        )}
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  activeChats: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  setIsGroupModalVisible: PropTypes.func.isRequired,
  setIsTutorModalVisible: PropTypes.func.isRequired,
  setSelectedChatId: PropTypes.func.isRequired,
  selectedChatId: PropTypes.number,
  unreadMessages: PropTypes.number,
  hoveredId: PropTypes.string,
  setHoveredId: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  loader: PropTypes.bool.isRequired,
  conversations: PropTypes.array.isRequired,
  fetchConversations: PropTypes.func.isRequired,
  setMentorModelVisible: PropTypes.func.isRequired,
}

Sidebar.defaultProps = {
  hoveredId: null,
  selectedChatId: null,
}

export default Sidebar

import React, { useEffect, useState } from 'react'
import { Table, Button, Card } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const IdeaFactory = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/IdeaFactoryProgram/Mentor/List?mentorId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: '*/*',
            },
          },
        )
        setData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Assigned By',
      dataIndex: 'assignedBy',
      key: 'assignedBy',
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="primary" onClick={() => navigate(`/mentor-detail/${record.id}`)}>
          View Detail
        </Button>
      ),
    },
  ]

  return (
    <Card className="card-professional" loading={loading} title="Idea Factory">
      <Table columns={columns} dataSource={data} rowKey="id" />
    </Card>
  )
}

export default IdeaFactory

import React, { useState, useEffect } from 'react'
import { Card, Select, Row, Col, Typography, Button, Tag, message } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import 'antd/dist/reset.css'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import predefinedStudentColors from 'src/Common/predefinedStudentColors'

const { Title, Text } = Typography
const { Option } = Select

const ProjectAssignment = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const { groupId } = useParams()
  const [selectedProject, setSelectedProject] = useState(null)
  const [groupData, setGroupData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(
          `https://soft.recipe4foodies.com/api/InternshipProject/Mentor/AssignProject-Request?groupId=${groupId}&mentorId=${userId}`,
          {
            headers: {
              accept: '*/*',
              Authorization: `Bearer ${token}`,
            },
          },
        )
        setGroupData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
        message.error(error.response.data)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [groupId, userId, token])

  const handleSubmit = async () => {
    if (!selectedProject) {
      message.warning('Please select a project first!')
      return
    }

    setSubmitting(true)

    const payload = {
      mentorId: parseInt(userId), // Convert to integer if needed
      groupId: parseInt(groupId),
      projectId: selectedProject,
      membersStepsMap: groupData.membersStepsMap,
    }

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/InternshipProject/Mentor/AssignProject',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )

      message.success('Project assigned successfully!')
      navigate(`/group-detail/${groupId}`) // Redirect after success
    } catch (error) {
      console.error('Error assigning project:', error)
      message.error('Failed to assign project. Please try again.')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div style={{ padding: '20px', margin: 'auto' }}>
      <Card
        loading={loading}
        className="card-professional"
        title="Assign Project to Group"
        extra={
          <Button type="default" onClick={() => navigate(`/group-detail/${groupId}`)}>
            Back
          </Button>
        }
      >
        {groupData && (
          <>
            <Title level={4}>
              Group: <span style={{ color: '#1677ff' }}>{groupData.groupName}</span>
            </Title>

            <Text strong style={{ fontSize: '16px', display: 'block', marginBottom: '10px' }}>
              Members:
            </Text>

            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
              {groupData.groupMembers?.map((member, index) => (
                <Tag
                  key={member.memberId}
                  color={predefinedStudentColors(index)}
                  style={{ fontSize: '14px', padding: '5px 12px', borderRadius: '20px' }}
                >
                  {member.name}
                </Tag>
              ))}
              <Tag
                style={{ fontSize: '14px', padding: '5px 12px', borderRadius: '20px' }}
                color={predefinedStudentColors(4)}
              >
                Team
              </Tag>
            </div>
          </>
        )}

        <br />
        <Select
          style={{ width: '100%', marginBottom: '20px' }}
          placeholder="Select a Project"
          size="large"
          onChange={(value) => setSelectedProject(value)}
        >
          {groupData?.availableProjects?.map((project) => (
            <Option key={project.id} value={project.id}>
              {project.title}
            </Option>
          ))}
        </Select>

        {selectedProject && (
          <>
            <Card
              style={{
                marginBottom: '20px',
                borderRadius: '10px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Title level={4}>Project Details</Title>
              <p>
                <Text strong>Title:</Text>{' '}
                {groupData.availableProjects.find((p) => p.id === selectedProject).title}
              </p>
              <p>
                <Text strong>Description:</Text>{' '}
                {groupData.availableProjects.find((p) => p.id === selectedProject).description}
              </p>
            </Card>

            <Title level={4} style={{ marginBottom: '20px' }}>
              Project Framework
            </Title>
            <Row gutter={[16, 16]}>
              {groupData.frameworkSteps.map((step) => (
                <Col key={step.id} xs={24} sm={12} md={12} lg={12}>
                  <Card title={step.title} bordered={false}>
                    <Text strong style={{ display: 'block', marginBottom: '10px' }}>
                      Assigned to:{' '}
                      {groupData.groupMembers.find((member) => member.memberId === step.memberId)
                        ?.name || 'Team'}
                    </Text>

                    <ul style={{ paddingLeft: '20px' }}>
                      {step.tasks.map((task, index) => (
                        <li key={index} style={{ marginBottom: '5px', fontSize: '14px' }}>
                          <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                          {task}
                        </li>
                      ))}
                    </ul>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
        {selectedProject && (
          <div style={{ textAlign: 'left', marginTop: '20px' }}>
            <Button type="primary" onClick={handleSubmit} loading={submitting}>
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default ProjectAssignment

import React, { useEffect, useState } from 'react'
import { Table, message, Card, Button } from 'antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const ProgressReport = () => {
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchReports()
  }, [])

  const fetchReports = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/InternshipStatusReport/Mentor/${userId}`,
        {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setReports(response.data)
    } catch (error) {
      message.error('Failed to fetch data. Please try again.')
      console.error('Error fetching reports:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleViewDetails = (reportId) => {
    navigate(`/progress-report-detail/${reportId}`) // Navigate with reportId
  }

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Week Number',
      dataIndex: 'weekNumber',
      key: 'weekNumber',
      sorter: (a, b) => a.weekNumber - b.weekNumber,
    },
    {
      title: 'Submission Date',
      dataIndex: 'submissionDate',
      key: 'submissionDate',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleViewDetails(record.reportId)}>
          View Details
        </Button>
      ),
    },
  ]

  return (
    <Card
      className="card-professional"
      loading={loading}
      title="Progress Report"
      extra={
        <Button type="default" onClick={() => navigate('/projects')}>
          Back
        </Button>
      }
    >
      <Table
        columns={columns}
        dataSource={reports}
        rowKey="reportId"
        pagination={{ pageSize: 5 }}
      />
    </Card>
  )
}

export default ProgressReport
